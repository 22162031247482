import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import UpdatePaymentSourceStepOne from '../../components/UpdatePaymentSourceStepOne';
import AddOrUpdatePaymentSourceStep from '../../components/AddOrUpdatePaymentSourceStep';
import AddPaymentSourceStepSuccess from '../../components/AddPaymentSourceStepSuccess';
import { ApplicationState, UPDATE_PAYMENT_SOURCE_STEP } from '../../store';
import { ApplicationAction } from '../../store/actions';
import { bindActionCreators, Dispatch } from 'redux';
import notifier from '../../dropin/notifier';
import { PaymentSource } from '@stronghold/pay-dropin';

interface StateProps {
    step: UPDATE_PAYMENT_SOURCE_STEP;
    paymentSource: PaymentSource | null;
}

interface DispatchProps {
    setStep: (step: UPDATE_PAYMENT_SOURCE_STEP) => void;
}

type Props = StateProps & DispatchProps;

class UpdatePaymentSourcePage extends React.PureComponent<Props> {
    componentDidMount() {
        notifier.ready();
    }

    render() {
        if (this.props.step === UPDATE_PAYMENT_SOURCE_STEP.ONE) {
            return (
                <UpdatePaymentSourceStepOne
                    showLoading={false}
                    onClick={() => this.props.setStep(UPDATE_PAYMENT_SOURCE_STEP.UPDATE)}
                />
            );
        } else if (this.props.step === UPDATE_PAYMENT_SOURCE_STEP.UPDATE) {
            return (
                <AddOrUpdatePaymentSourceStep
                    mode="update"
                    onUpdate={() => {
                        this.props.setStep(UPDATE_PAYMENT_SOURCE_STEP.SUCCESS);
                        notifier.success(this.props.paymentSource);
                    }}
                />
            );
        } else if (this.props.step === UPDATE_PAYMENT_SOURCE_STEP.SUCCESS) {
            return <AddPaymentSourceStepSuccess />;
        } else {
            return null;
        }
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state: ApplicationState) => ({
    step: state.paymentSource.updateStep,
    paymentSource: state.paymentSource.paymentSource,
});

const mapDispatchToProps = (dispatch: Dispatch<ApplicationAction>) =>
    bindActionCreators(
        {
            setStep: (step: UPDATE_PAYMENT_SOURCE_STEP) =>
                dispatch({
                    type: 'SET_UPDATE_PAYMENT_SOURCE_STEP',
                    payload: step,
                }),
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePaymentSourcePage);
