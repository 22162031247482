import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import CoBrand from './CoBrand';
import DropinBody from './DropinBody';
import DropinContent from './DropinContent';
import DropinFooter from './DropinFooter';
import Button from 'reactstrap/lib/Button';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../store';
import notifier from '../dropin/notifier';

interface StateProps {
    paymentSourceLabel: string;
}

interface OwnProps {}

interface DispatchProps {}

type Props = StateProps & OwnProps & DispatchProps;

class AddPaymentSourceStepSuccess extends React.PureComponent<Props> {
    render() {
        return (
            <DropinContent>
                <DropinBody>
                    <CoBrand />
                    <div data-sh="message">
                        Thanks, your <strong data-dropin="payment-source-label">{this.props.paymentSourceLabel}</strong>{' '}
                        account has been verified for payment.
                    </div>
                </DropinBody>
                <DropinFooter>
                    <Button id="finish-button" color="primary" block onClick={() => notifier.exit()}>
                        Finish
                    </Button>
                </DropinFooter>
            </DropinContent>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state: ApplicationState) => ({
    paymentSourceLabel: state.paymentSource.paymentSource?.label || '',
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentSourceStepSuccess);
