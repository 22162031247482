import * as React from 'react';
import { FormEvent, Ref } from 'react';
import { Button, FormFeedback } from 'reactstrap';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState, AuthenticationMethod, CustomerSignupRequestModel } from '../store';
import { bindActionCreators, Dispatch } from 'redux';
import FormGroup from 'reactstrap/lib/FormGroup';
import Form from 'reactstrap/lib/Form';
import { StrongholdPayError } from '@stronghold/pay-dropin';
import MaskedInput from 'react-text-mask';
import { verifyAction } from '../store/actionCreators/findCustomer';
import { getErrorMessage } from '../dropin/utils';

interface StateProps {
    requesting: boolean;
    error: StrongholdPayError | null;
    signupRequest: CustomerSignupRequestModel | null;
    token: string | null;
}

type OwnProps = {
    authenticationMethod: AuthenticationMethod;
    value: string;
    isResending: boolean;
    onResend: () => void;
    onSuccess?: () => void;
    submitRef: Ref<any>;
    disabled: (disabled: boolean) => void;
};

interface DispatchProps {
    verify: typeof verifyAction;
}

type Props = OwnProps & StateProps & DispatchProps;

type State = {
    code: string;
};

class VerificationCode extends React.Component<Props> {
    public state: State = {
        code: '',
    };

    onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await this.props.verify(this.state.code.replace(/ /g, '').replace(/_/g, ''));

        if (this.props.onSuccess && !this.props.error) {
            this.props.onSuccess();
        }
    };

    isDisabled = () =>
        !this.props.token ||
        this.props.isResending ||
        this.props.requesting ||
        this.state.code === '' ||
        this.state.code.replace(/ /g, '').replace(/_/g, '').length !== 4;

    render() {
        const { requesting, error, isResending, submitRef, value, disabled } = this.props;
        disabled(this.isDisabled());

        return (
            <React.Fragment>
                <Form onSubmit={this.onSubmit}>
                    <p>
                        Enter the verification code sent to <strong>{value}</strong>.
                    </p>
                    <FormGroup className="mb-0">
                        <MaskedInput
                            mask={[/[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/]}
                            type="text"
                            placeholder="X X X X"
                            className={['form-control form-control-lg text-center', error ? 'is-invalid' : ''].join(
                                ' ',
                            )}
                            onChange={(e) => this.setState({ code: e.currentTarget.value })}
                            ref={(input) => {
                                setTimeout(() => {
                                    if (input && input.inputElement) {
                                        input.inputElement.focus();
                                    }
                                }, 500);
                            }}
                        />
                        <FormFeedback>{error && getErrorMessage(error, null)}</FormFeedback>
                    </FormGroup>
                    <Button
                        className="mt-3"
                        color="light"
                        onClick={this.props.onResend}
                        disabled={requesting || isResending}
                    >
                        Resend
                    </Button>
                    <button ref={submitRef} type={'submit'} disabled={this.isDisabled()} style={{ display: 'none' }} />
                </Form>
            </React.Fragment>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    error: state.error?.error,
    requesting: state.customer.requesting,
    signupRequest: state.customer.signup,
    token: state.customer.token,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            verify: verifyAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(VerificationCode);
