import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import AddPaymentSourcePage from './pages/dropin/AddPaymentSourcePage';
import UpdatePaymentSourcePage from './pages/dropin/UpdatePaymentSourcePage';
import AddChargePage from './pages/dropin/AddChargePage';
import AddTipPage from './pages/dropin/AddTipPage';
import HomePage from './pages/HomePage';
import DropinLayout from './components/DropinLayout';
import FindCustomerPage from "./pages/dropin/FindCustomerPage";

export default () => (
    <Switch>
        <Route path="/dropin">
            <DropinLayout>
                <Switch>
                    <Route path="/dropin/paymentsources/add" component={AddPaymentSourcePage} />
                    <Route path="/dropin/paymentsources/update" component={UpdatePaymentSourcePage} />
                    <Route path="/dropin/charges/add" component={AddChargePage} />
                    <Route path="/dropin/tips/add" component={AddTipPage} />
                    <Route path="/dropin/customers" component={FindCustomerPage} />
                    <Redirect to="/" />
                </Switch>
            </DropinLayout>
        </Route>
        <Route exact path="/" component={HomePage} />
        <Redirect to={'/'} />
    </Switch>
);
