import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';

interface StateProps {
    merchantDisplayName: string;
    paymentSourceLabel: string;
}

interface OwnProps {
    amount: string;
}

interface DispatchProps {}

type Props = StateProps & OwnProps & DispatchProps;

class PaymentAuthorizationAggreement extends React.PureComponent<Props> {
    render() {
        return (
            <small className="text-center" data-sh="description">
                By clicking the button above, you authorize <strong>{this.props.merchantDisplayName}</strong> to
                electonically debit your{' '}
                <strong data-dropin="payment-source-label">{this.props.paymentSourceLabel}</strong> for{' '}
                <strong>{this.props.amount}</strong>.
            </small>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state: ApplicationState) => {
    return {
        merchantDisplayName: state.dropin.merchant?.display_name || '',
        paymentSourceLabel: state.paymentSource.paymentSource?.label || '',
    };
};

export default connect(mapStateToProps)(PaymentAuthorizationAggreement);
