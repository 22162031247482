import * as reducers from './reducers';
import { History } from 'history';
import thunk from 'redux-thunk';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { PaymentSourceState, DropinState, ErrorState, TransactionState, AggregatorState, CustomerState } from './types';

// The top-level state object
export interface ApplicationState {
    dropin: DropinState;
    error: ErrorState;
    paymentSource: PaymentSourceState;
    transaction: TransactionState;
    aggregator: AggregatorState;
    customer: CustomerState;
}

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

export default function configureStore<T>(history: History<T>, initialState?: ApplicationState) {
    const middleware = [thunk, routerMiddleware(history)];

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history),
    });

    const enhancers: any[] = [];
    const windowIfDefined = typeof window === 'undefined' ? null : (window as any); // eslint-disable-line @typescript-eslint/no-explicit-any
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));
}
