import React from 'react';
import {connect, MapStateToPropsParam} from 'react-redux';
import AddPaymentSourceStepOne from '../../components/AddPaymentSourceStepOne';
import AddOrUpdatePaymentSourceStep from '../../components/AddOrUpdatePaymentSourceStep';
import AddPaymentSourceStepSuccess from '../../components/AddPaymentSourceStepSuccess';
import {ADD_PAYMENT_SOURCE_STEP, ApplicationState} from '../../store';
import notifier from '../../dropin/notifier';
import AddPaymentSourceStepSelection from '../../components/AddPaymentSourceStepSelection';
import SelectPaymentSourceMethodStep from '../../components/SelectPaymentSourceMethodStep';
import AddCreditCardPaymentSourceStepOne from '../../components/AddCreditCardPaymentSourceStepOne';
import {bindActionCreators, Dispatch} from "redux";
import {ApplicationAction} from "../../store/actions";
import UpdatePaymentSourceStepOne from "../../components/UpdatePaymentSourceStepOne";
import {RetryAddPaymentSourceModel} from "../../api";

interface StateProps {
    step: ADD_PAYMENT_SOURCE_STEP;
    hasCreditCardPayments: boolean | null;
    retryModel?: RetryAddPaymentSourceModel;
}

interface DispatchProps {
    setStep: (step: ADD_PAYMENT_SOURCE_STEP) => void;
}

type Props = StateProps & DispatchProps;

class AddPaymentSourcePage extends React.PureComponent<Props> {
    componentDidMount() {
        notifier.ready();
    }

    render() {
        if (this.props.step === ADD_PAYMENT_SOURCE_STEP.METHOD && this.props.hasCreditCardPayments) {
            return <SelectPaymentSourceMethodStep />;
        } else if (this.props.step === ADD_PAYMENT_SOURCE_STEP.ONE) {
            return <AddPaymentSourceStepOne />;
        } else if (this.props.step === ADD_PAYMENT_SOURCE_STEP.CC_ONE) {
            return <AddCreditCardPaymentSourceStepOne />;
        } else if (this.props.step === ADD_PAYMENT_SOURCE_STEP.LINK) {
            return <AddOrUpdatePaymentSourceStep mode="add"/>;
        } else if (this.props.step === ADD_PAYMENT_SOURCE_STEP.LOGIN_REQUIRED_VERIFY) {
            return (
                <UpdatePaymentSourceStepOne
                    showLoading={true}
                    onClick={() => this.props.setStep(ADD_PAYMENT_SOURCE_STEP.LOGIN_REQUIRED)}
                    isRetry={true}
                    retryModel={this.props.retryModel}
                />
            );
        } else if (this.props.step === ADD_PAYMENT_SOURCE_STEP.LOGIN_REQUIRED) {
            return <AddOrUpdatePaymentSourceStep mode="retry_login"/>;
        } else if (this.props.step === ADD_PAYMENT_SOURCE_STEP.SELECTION) {
            return <AddPaymentSourceStepSelection />;
        } else {
            return <AddPaymentSourceStepSuccess />;
        }
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state: ApplicationState) => ({
    step: state.paymentSource.addStep,
    hasCreditCardPayments: state.dropin.merchant && state.dropin.merchant.has_credit_card_payments,
    retryModel: state.paymentSource.retryModel ?? undefined,
});

const mapDispatchToProps = (dispatch: Dispatch<ApplicationAction>) =>
    bindActionCreators(
        {
            setStep: (step: ADD_PAYMENT_SOURCE_STEP) =>
                dispatch({
                    type: 'SET_ADD_PAYMENT_SOURCE_STEP',
                    payload: step,
                }),
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentSourcePage);
