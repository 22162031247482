import {ERROR_CODE, ERROR_TYPE, StrongholdPayError} from '@stronghold/pay-dropin';
import {ParsedQuery} from 'query-string';
import {AppThunkAction} from '../configureStore';
import {client} from '../../api';
import notifier from '../../dropin/notifier';
import {ApplicationAction} from '../actions';
import {ADD_PAYMENT_SOURCE_STEP, CUSTOMER_PAGE, QueryData} from '../types';
import * as DataDog from "../../datadog";
import {StatusType} from "@datadog/browser-logs";
import {Severity} from "@sentry/react";

export function initDropinAction(parsedQuery: ParsedQuery, pathname: string): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        const query = (parsedQuery as unknown) as QueryData;
        const plaidOauth = isPlaidOauth(window.location.href);
        
        const handleError = (error: StrongholdPayError) => {
            notifier.ready();
            dispatch({type: 'SET_ERROR', payload: error});
            setTimeout(() => {
                dispatch({type: 'SHOW_ERROR', payload: true});
                dispatch({type: 'SET_AS_READY'});
            }, 50);
            notifier.exit();
        };

        let customerToken = '';
        let publishableKey: string;
        if (plaidOauth) {
            let cacheKey = getSubdomain(window.location.href);
            if (cacheKey) {
                let cacheResponse = await client.getPlaidLinkTokenCacheValue(cacheKey);
                customerToken = cacheResponse.result.customer_token;
                publishableKey = cacheResponse.result.publishable_key;
            }
        } else {
            if (query.publishableKey) {
                dispatch({
                    type: 'SET_PUBLISHABLE_KEY',
                    payload: query.publishableKey,
                });

                publishableKey = query.publishableKey;
            } else {
                handleError(new StrongholdPayError(ERROR_TYPE.AUTH_ERROR, ERROR_CODE.INVALID_API_KEY, 'Invalid API Key.'));
                return;
            }

            if (query.customerToken) {
                dispatch({
                    type: 'SET_CUSTOMER_TOKEN',
                    payload: query.customerToken,
                });

                customerToken = query.customerToken;
            } else if (!pathname.includes("dropin/customers")) {
                handleError(
                    new StrongholdPayError(
                        ERROR_TYPE.INVALID_REQUEST_ERROR,
                        ERROR_CODE.INVALID_CUSTOMER_TOKEN,
                        'Invalid customer token.',
                    ),
                );
                return;
            }
        }

        if (query.integrationId) {
            dispatch({
                type: 'SET_INTEGRATION_ID',
                payload: query.integrationId,
            })
        } else {
            // TODO: Eventually add error handling for missing integrationId
            // handleError(
            //     new StrongholdPayError(
            //         ERROR_TYPE.INVALID_REQUEST_ERROR,
            //         ERROR_CODE.MISSING_FIELD,
            //         ERROR_MESSAGE.ATTRIBUTE_REQUIRED,
            //         'integrationId',
            //     )
            // )
            if (!query.payLinkCode) {
                // Only creating DataDog log if the JS SDK was initialized outside of a PayLink
                DataDog.addDataDogLog(
                    `Missing IntegrationId for merchant with publishable key: ${query.publishableKey}`,
                    {level: Severity.Warning},
                    StatusType.info);
            }
        }

        try {
            const body = await client.initDropin(publishableKey!, customerToken, pathname, query.apiVersion, query.integrationId);
            dispatch({ type: 'SET_CONFIGURATION', payload: body.result });
            if (plaidOauth) {
                dispatch({
                    type: 'SET_ADD_PAYMENT_SOURCE_STEP',
                    payload: ADD_PAYMENT_SOURCE_STEP.LINK,
                });
            } else if (body.result.merchant.has_credit_card_payments) {
                // Enable payment selection page 
                dispatch({type: 'SET_ADD_PAYMENT_SOURCE_STEP', payload: ADD_PAYMENT_SOURCE_STEP.METHOD});
                const manifest = await client.generateTsepManifest();
                dispatch({type: 'SET_TSEP_MANIFEST', payload: manifest.result});
            }

            dispatch({
                type: 'SET_CUSTOMER_PAGE',
                payload: query.isLogin?.toLowerCase() === 'true' ? CUSTOMER_PAGE.LOGIN : CUSTOMER_PAGE.SIGNUP
            })
            dispatch({type: 'SET_QUERY_DATA', payload: query});
            dispatch({type: 'SET_AS_READY'});
        } catch (error) {
            handleError(error);
        }
    };
}

export function getSubdomain(url: string): string | null {
    const parsedUrl = new URL(url);
    const hostname = parsedUrl.hostname;
    const parts = hostname.split('.');

    // assume the URLs are in the format of `subdomain.domain.com`
    if (parts.length > 2) {
        return parts[0];
    } else {
        return null;
    }
}

export function isPlaidOauth(pathname: string): boolean {
    return pathname.includes('?oauth_state_id=');
}
