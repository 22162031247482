import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import CoBrand from './CoBrand';
import DropinBody from './DropinBody';
import DropinContent from './DropinContent';
import DropinFooter from './DropinFooter';
import Button from 'reactstrap/lib/Button';
import notifier from '../dropin/notifier';
import { ApplicationState } from '../store';

interface StateProps {
    merchantDisplayName: string;
}

type Props = StateProps;
class CustomerLoginStepSuccess extends React.PureComponent<Props> {
    render() {
        const { merchantDisplayName } = this.props;
        return (
            <DropinContent>
                <DropinBody>
                    <CoBrand />
                    <div data-sh="message">
                        Thanks, you have successfully logged into your <strong>Stronghold</strong> account for{' '}
                        <strong>{merchantDisplayName}</strong>.
                    </div>
                </DropinBody>
                <DropinFooter>
                    <Button id="finish-button" color="primary" block onClick={() => notifier.exit()}>
                        Finish
                    </Button>
                </DropinFooter>
            </DropinContent>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state: ApplicationState) => ({
    merchantDisplayName: state.dropin.merchant?.display_name || '',
});

export default connect(mapStateToProps)(CustomerLoginStepSuccess);
