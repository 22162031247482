import {datadogLogs, StatusType} from '@datadog/browser-logs'

datadogLogs.setLoggerGlobalContext({ service: 'pay-api' })

export function initializeDataDog({
  clientToken,
  site,
  environment,  
}: {
    clientToken: string;
    site: string;
    environment: string;
}) {
    if (!clientToken || !environment) {
        return;
    }

    datadogLogs.init({
        clientToken: clientToken,
        env: environment,
        site: site,
        forwardErrorsToLogs: true,
        sampleRate: 100,
        service: 'pay-api',
    })
}

export function addDataDogLog(
    message: string,
    messageContext: object,
    status: StatusType,
) {
    datadogLogs.logger.log(message, messageContext, status)
}