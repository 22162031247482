import { ApplicationState } from '../configureStore';
import {StrongholdPayError} from "@stronghold/pay-dropin";

export function toNumber(str: string | null | undefined): number | null {
    if (!str) {
        return null;
    }

    const result = parseInt(str);

    if (isNaN(result)) {
        return null;
    }

    return result;
}

export function toCurrency(number: number | string | null | undefined): string | null {
    if (typeof number === 'string') {
        number = toNumber(number);
    }

    if (typeof number !== 'number') {
        return null;
    }

    const format = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(number / 100);

    return format;
}

export function getPaymentSourceId(applicationState: ApplicationState) {
    return (
        applicationState.dropin.query?.paymentSourceId ||
        applicationState.dropin.query?.charge?.paymentSourceId ||
        applicationState.dropin.query?.tip?.paymentSourceId ||
        null
    );
}

export function isPropertyError(propertyName: string | null, error: StrongholdPayError | null) {
    return (error && error.property === propertyName) || false;
}
