import { StrongholdPayError } from '@stronghold/pay-dropin';
import { Reducer } from 'redux';
import notifier from '../../dropin/notifier';
import { getDefaultError } from '../../dropin/utils';
import { ErrorAction } from '../actions/error';
import { ErrorState } from '../types/error';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ErrorState = {
    error: null,
    message: null,
    show: false,
};

export const error: Reducer<ErrorState, ErrorAction> = (state: ErrorState = unloadedState, action: ErrorAction) => {
    switch (action.type) {
        case 'SET_ERROR':
            if (action.notify !== false) {
                notifier.error(action.payload);
            }
            return {
                ...state,
                error: action.payload instanceof StrongholdPayError ? action.payload : getDefaultError(),
            };
        case 'RESET_ERROR':
            return {
                ...state,
                error: null,
            }
        case 'SHOW_ERROR':
            return {
                ...state,
                show: action.payload,
            };
        case 'SET_ERROR_MESSAGE':
            return {
                ...state,
                message: action.payload,
            };
        default:
            return state;
    }
};
