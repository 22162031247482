import axios from 'axios';
import {
    AddChargeConfig,
    AddChargeRequestModel,
    AddPaymentSourceConfig,
    AddTipConfig,
    AddTipRequestModel,
    Configuration,
    MaxPromotionValue,
    ProcessingFeeAmountModel,
    Response,
    RetryAddPaymentSourceModel,
    TsepEnvironment,
    UpdatePaymentSourceConfig,
} from './types';
import * as querystring from 'query-string';
import {Charge, Customer, PaymentSource, StrongholdPayError, Tip} from '@stronghold/pay-dropin';
import {getDefaultError} from '../dropin/utils';
import {TsepManifest} from '../types/tsep';
import {
    CardSourceConfig,
    CustomerLoginRequestModel,
    CustomerSignupRequestModel,
    CustomerTokenResponseModel,
    CustomerVerifyRequestModel,
    PlaidLinkCacheValueModel,
} from '../store';

class Client {
    public publishableKey = '';
    public customerToken = '';
    private _integrationId?: string | null | undefined;
    private _apiVersion = 'v2';
    private _pathname = '';

    private async request<T>(
        url: string,
        options: {
            method: 'GET' | 'POST';
            data?: object;
            query?: object;
            apiVersion?: string | null | undefined;
        },
    ): Promise<Response<T>> {
        try {
            let apiVersion = options.apiVersion ?? this._apiVersion;
            const response = await axios({
                url: `${apiVersion}/${url}?${querystring.stringify({
                    publishableKey: this.publishableKey,
                    customerToken: this.customerToken,
                    integrationId: this._integrationId,
                    pathname: this._pathname,
                    ...options.query
                })}`,
                method: options.method,
                data: options.data,
            });

            return response.data;
        } catch (error) {
            let strongholdPayError: StrongholdPayError;

            if (error.response && error.response.data && error.response.data.error) {
                strongholdPayError = error.response.data.error as StrongholdPayError;
                strongholdPayError = getDefaultError(
                    strongholdPayError.type,
                    strongholdPayError.code,
                    strongholdPayError.message,
                    strongholdPayError.property || undefined,
                    strongholdPayError.reference || undefined,
                );
            } else {
                strongholdPayError = getDefaultError();
            }

            throw strongholdPayError;
        }
    }

    async initDropin(publishableKey: string, customerToken: string, pathname: string, apiVersion?: string | null, integrationId?: string | null) {
        this.publishableKey = publishableKey;
        this.customerToken = customerToken;
        this._integrationId = integrationId;
        this._pathname = pathname;
        let config = await this.request<Configuration>('dropin/api/init', {
            method: 'GET',
            apiVersion: 'v2',
        });
        
        if (apiVersion) {
            this._apiVersion = apiVersion;
        }
        
        return config;
    }

    async getAddPaymentSourceConfig(payLinkId: string) {
        return await this.request<AddPaymentSourceConfig>(`dropin/api/payment-sources/add`,
            {
                method: 'GET', 
                query: {
                    payLinkId: payLinkId,
                },
                apiVersion: 'v2',
            });
    }

    async addPaymentSourceCreditCard(data: CardSourceConfig) {
        var body = {
            card_holder_name: data.cardHolderName,
            tsep_token: data.tsepToken,
            cvv2: data.cvv2,
            expiration_date: data.expirationDate,
            card_type: data.cardType,
            masked_card_number: data.maskedCardNumber,
            zip: data.zip,
        };
        return await this.request<PaymentSource>('dropin/api/payment-sources/add/credit-card', {
            method: 'POST',
            data: body,
            apiVersion: 'v2',
        });
    }

    async linkPlaidPaymentSource(public_token: string, account_id: string, pay_link_id?: string | null) {
        return await this.request<PaymentSource>('dropin/plaid/link', {
            method: 'POST',
            data: {
                pay_link_id,
                plaid: {
                    account_id,
                    public_token,
                },
            },
            apiVersion: 'v2',
        });
    }

    async linkYodleePaymentSource(data: { payLinkId?: string | null; providerAccountId: number; sites: YodleeSite[] }) {
        var body = {
            pay_link_id: data.payLinkId,
            yodlee: {
                provider_account_id: data.providerAccountId,
                sites: data.sites.map((value) => ({
                    account_id: value.accountId,
                    additional_status: value.additionalStatus,
                    provider_account_id: value.providerAccountId,
                    provider_id: value.providerId,
                    provider_name: value.providerName,
                    request_id: value.requestId,
                    status: value.status,
                })),
            },
        };

        return await this.request<PaymentSource>('dropin/yodlee/link', {
            method: 'POST',
            data: body,
            apiVersion: 'v2',
        });
    }

    async listFinicityAccounts() {
        return await this.request<FinicityAccount[]>('dropin/finicity/accounts', {
            method: 'GET',
            apiVersion: 'v2',
        });
    }

    async linkFinicityPaymentSource(data: { payLinkId?: string | null; finicityAccountId: number }) {
        return await this.request<PaymentSource>('dropin/finicity/link', {
            method: 'POST',
            data: {
                pay_link_id: data.payLinkId,
                finicity_account_id: data.finicityAccountId,
            },
            apiVersion: 'v2',
        });
    }
    
    async getRetryPaymentSourceLinkConfig(data: RetryAddPaymentSourceModel | undefined) {
        return await this.request<UpdatePaymentSourceConfig>('dropin/api/payment-sources/retry', {
            method: 'POST',
            data,
            apiVersion: 'v2',
        });
    }

    async getUpdatePaymentSourceConfig(payment_source_id: string) {
        return await this.request<UpdatePaymentSourceConfig>('dropin/api/payment-sources/update', {
            method: 'POST',
            data: {
                payment_source_id,
            },
            apiVersion: 'v2',
        });
    }

    async getAddChargeConfig(payment_source_id: string) {
        return await this.request<AddChargeConfig>('dropin/api/charges/add/config', {
            method: 'POST',
            data: {
                payment_source_id,
            },
        });
    }

    async postCharge(data: AddChargeRequestModel) {
        return await this.request<Charge>('dropin/api/charges/add', {
            method: 'POST',
            data,
        });
    }

    async getAddTipConfig(payment_source_id: string, charge_id: string) {
        return await this.request<AddTipConfig>('dropin/api/tips/add/config', {
            method: 'POST',
            data: {
                payment_source_id,
                charge_id,
            },
        });
    }

    async postTip(data: AddTipRequestModel) {
        return await this.request<Tip>('dropin/api/tips/add', {
            method: 'POST',
            data,
        });
    }

    async generateTsepManifest() {
        return await this.request<TsepManifest>('dropin/api/tsep/generate-manifest', {
            method: 'GET',
            apiVersion: 'v2',
        });
    }

    async getTsepEnvironment() {
        return await this.request<TsepEnvironment>('dropin/api/tsep/environment', {
            method: 'GET',
            apiVersion: 'v2',
        });
    }

    async getMaxRedeemableCreditValue(chargeAmount: number) {
        return await this.request<MaxPromotionValue>(`dropin/api/promotions/max-redeemable-value`, {
            method: 'GET',
            query: {
                charge_amount: chargeAmount,
            },
        });
    }

    async getProcessingFeeValue(chargeAmount: number) {
        return await this.request<ProcessingFeeAmountModel>(`dropin/api/processing-fees`, {
            method: 'GET',
            query: {
                charge_amount: chargeAmount,
            },
        });
    }

    async signup(data: CustomerSignupRequestModel) {
        return await this.request<CustomerTokenResponseModel>('dropin/api/customers/signup',{
            method: 'POST',
            data,
        });
    }

    async login(data: CustomerLoginRequestModel) {
        return await this.request<CustomerTokenResponseModel>('dropin/api/customers/login', {
            method: 'POST',
            data,
        });
    }

    async verify(data: CustomerVerifyRequestModel) {
        return await this.request<Customer>('dropin/api/customers/verify-token', {
            method: 'POST',
            data,
        });
    }

    async createPlaidLinkTokenCacheValue(key: string, data: PlaidLinkCacheValueModel) {
        return await this.request(`plaid/link-token-cache/${key}`, {
            method: 'POST',
            data,
            apiVersion: 'v2',
        });
    }

    async getPlaidLinkTokenCacheValue(key: string) {
        return await this.request<PlaidLinkCacheValueModel>(`plaid/link-token-cache/${key}`, {
            method: 'GET',
            apiVersion: 'v2',
        });
    }
}

export const client = new Client();
