import React, { createRef } from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';
import DropinContent from './DropinContent';
import DropinBody from './DropinBody';
import CoBrand from './CoBrand';
import DropinFooter from './DropinFooter';
import LoginForm from './LoginForm';
import Loader from './Loader';
import Button from 'reactstrap/lib/Button';
import CustomerFooter from './CustomerFooter';

interface StateProps {
    requesting: boolean;
}
interface State {
    disabled: boolean;
}

type Props = StateProps;
class CustomerLoginStepOne extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            disabled: true,
        };
    }

    submitRef = createRef<HTMLInputElement>();

    toggleLoginButton = (disabled: boolean) => {
        this.setState({ disabled: disabled });
    };
    render() {
        const { requesting } = this.props;
        const { disabled } = this.state;
        let content = <LoginForm submitRef={this.submitRef} disabled={this.toggleLoginButton} />;

        return (
            <DropinContent>
                <DropinBody>
                    <CoBrand />
                    <div className="overflow-auto" style={{ maxHeight: 280 }}>
                        {content}
                    </div>
                </DropinBody>
                <DropinFooter>
                    <Button
                        id="customer-login-button"
                        color="primary"
                        disabled={requesting || disabled}
                        block
                        onClick={() => this.submitRef.current?.click()}
                    >
                        {requesting ? <Loader color="light" size="sm" /> : 'Log in'}
                    </Button>
                    <CustomerFooter />
                </DropinFooter>
            </DropinContent>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state: ApplicationState) => ({
    requesting: state.customer.requesting,
});

export default connect(mapStateToProps)(CustomerLoginStepOne);
