import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState, CUSTOMER_LOGIN_STEP } from '../store';
import CustomerLoginStepOne from './CustomerLoginStepOne';
import CustomerLoginStepSuccess from './CustomerLoginStepSuccess';
import CustomerLoginStepTwo from './CustomerLoginStepTwo';

interface StateProps {
    requesting: boolean;
    step: CUSTOMER_LOGIN_STEP;
}

type Props = StateProps;

class Login extends React.PureComponent<Props> {
    render() {
        if (this.props.step === CUSTOMER_LOGIN_STEP.ONE) {
            return <CustomerLoginStepOne />;
        } else if (this.props.step === CUSTOMER_LOGIN_STEP.TWO) {
            return <CustomerLoginStepTwo />;
        } else if (this.props.step === CUSTOMER_LOGIN_STEP.SUCCESS) {
            return <CustomerLoginStepSuccess />;
        } else {
            return null;
        }
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state: ApplicationState) => ({
    requesting: state.paymentSource.requesting,
    step: state.customer.loginStep,
});

export default connect(mapStateToProps)(Login);
