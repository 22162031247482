import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import CoBrand from './CoBrand';
import DropinBody from './DropinBody';
import DropinContent from './DropinContent';
import DropinFooter from './DropinFooter';
import PaymentAuthorizationAggreement from './PaymentAuthorizationAggreement';
import { ApplicationState } from '../store';
import * as selectors from '../store/selectors';
import { bindActionCreators, Dispatch } from 'redux';
import Loader from './Loader';
import Button from 'reactstrap/lib/Button';

interface StateProps {
    amount: string;
    beneficiaryName: string;
    chargeAmount: string;
    displayMessage?: string;
}

interface OwnProps {
    requesting: boolean;
    onClick: () => void;
}

interface DispatchProps {}

type Props = StateProps & OwnProps & DispatchProps;

class AddTipStepOne extends React.PureComponent<Props> {
    render() {
        return (
            <DropinContent>
                <DropinBody>
                    <CoBrand />
                    <div className="text-primary display-4 mb-4" data-sh="tip-amount">
                        {this.props.amount}
                    </div>
                    {this.props.displayMessage ? (
                        <p data-sh="tip-message">{this.props.displayMessage}</p>
                    ) : (
                        <React.Fragment>
                            <div className="mb-1">Order completed by</div>
                            <div className="h4 font-weight-normal mb-1" data-sh="tip-beneficiary">
                                {this.props.beneficiaryName}
                            </div>
                            <div className="text-muted" data-sh="amount">
                                for {this.props.chargeAmount}
                            </div>
                        </React.Fragment>
                    )}
                </DropinBody>
                <DropinFooter>
                    <Button
                        id="pay-button"
                        color="primary"
                        disabled={this.props.requesting}
                        block
                        onClick={this.props.onClick}
                    >
                        {this.props.requesting ? <Loader color="light" size="sm" /> : 'Tip'}
                    </Button>
                    <PaymentAuthorizationAggreement amount={this.props.amount} />
                </DropinFooter>
            </DropinContent>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state: ApplicationState) => {
    return {
        amount: selectors.toCurrency(state.dropin.query?.tip?.amount || 0) || '',
        chargeAmount: selectors.toCurrency(state.transaction.charge?.amount || 0) || '',
        beneficiaryName: state.dropin.query?.tip?.beneficiaryName || '',
        displayMessage: state.dropin.query?.tip?.details?.displayMessage || undefined,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddTipStepOne);
