import { EVENT, StrongholdPayError } from '@stronghold/pay-dropin';
import { getDefaultError } from './utils';

// notifier used by integrators w/ mobile apps
class Notifier {
    private _parentOrigin = '*';
    private _parent = window.parent;

    private _readyCalled = false;

    private notify(event: EVENT, err: StrongholdPayError | null = null, data: any = null) {
        console.debug(`notify event "${event}" -`, err, JSON.stringify(data || {}));
        const object = {
            event: event,
            payload: {
                err: err,
                data: data,
            },
        };

        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify(object));
        } else {
            this._parent.postMessage(JSON.parse(JSON.stringify(object)), '*');
        }
    }

    error(e?: StrongholdPayError) {
        e = e ?? getDefaultError();
        console.error(
            `error ${e.type} - ${e.code} (property="${e.property || ''}", reference="${e.reference || ''})": ${
                e.message
            }`,
        );
        this.notify(EVENT.ERROR, e);
        return e;
    }

    success(data?: any) {
        this.notify(EVENT.SUCCESS, null, data);
    }

    exit() {
        this.notify(EVENT.EXIT);
    }

    ready() {
        if (this._readyCalled) {
            return;
        }
        this._readyCalled = true;
        this.notify(EVENT.READY);
    }
}

export default new Notifier();
