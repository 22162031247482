import { PaymentSource } from '@stronghold/pay-dropin';

export enum CUSTOMER_PAGE {
    LOGIN = 'login',
    SIGNUP = 'signup',
}

export enum CUSTOMER_SIGNUP_STEP {
    ONE = 'one',
    TWO = 'two',
    THREE = 'three',
    SUCCESS = 'success',
}

export enum CUSTOMER_LOGIN_STEP {
    ONE = 'one',
    TWO = 'two',
    SUCCESS = 'success',
}

export type AuthenticationMethod = 'sms' | 'email';

export interface CustomerState {
    page: CUSTOMER_PAGE;
    signupStep: CUSTOMER_SIGNUP_STEP;
    loginStep: CUSTOMER_LOGIN_STEP;
    requesting: boolean;
    authenticationMethod: AuthenticationMethod;
    token: string | null;
    signup: CustomerSignupRequestModel | null;
    login: CustomerLoginRequestModel | null;
}

export interface AuthenticationModel {
    method: AuthenticationMethod;
}

export interface CustomerModel {
    id: string | null;
    country: string | null;
    state: string | null;
    individual: CustomerIndividualDetails;
    payment_sources: PaymentSource[];
}

export interface CustomerIndividualDetails {
    email: string | null;
    mobile: string | null;
    first_name: string | null;
    last_name: string | null;
    date_of_birth: string | null;
}

export interface CustomerSignupRequestModel {
    authentication_method: string;
    customer_details: CustomerIndividualDetails;
}

export interface CustomerSignupResponseModel {
    token: string;
}

export interface CustomerLoginRequestModel {
    authentication_method: string;
    username: string;
}

export interface CustomerTokenResponseModel {
    token: string;
}

export interface CustomerVerifyRequestModel {
    token: string;
    code: string;
}
