import * as React from 'react';
import { FormEvent, Ref } from 'react';
import Form from 'reactstrap/lib/Form';
import Input from 'reactstrap/lib/Input';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState, CUSTOMER_SIGNUP_STEP, CustomerSignupRequestModel } from '../store';
import querystring from 'querystring';
import { Link } from 'react-router-dom';
import { AuthenticationMethod } from '../store';
import { setAuthenticationMethod, setCustomerSignupStep, signupAction } from '../store/actionCreators/findCustomer';
import PhoneNumberInput from './PhoneNumberInput';
import { StrongholdPayError } from '@stronghold/pay-dropin';
import { isPropertyError } from '../store/selectors';
import { FormFeedback } from 'reactstrap';
import { formatDob, formatDobDisplay, getErrorMessage, isValidDob, isValidEmail } from '../dropin/utils';

interface StateProps {
    requesting: boolean;
    error: StrongholdPayError | null;
    authenticationMethod: AuthenticationMethod;
    signup: CustomerSignupRequestModel | null;
}

interface DispatchProps {
    setAuthenticationMethod: typeof setAuthenticationMethod;
    setCustomerSignupStep: typeof setCustomerSignupStep;
    customerSignup: typeof signupAction;
    resetCustomerSignup: () => void;
}

interface OwnProps {
    submitRef: Ref<any>;
    disabled: (disabled: boolean) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    mobile: string;
    email: string;
    showSms: boolean;
    agree: boolean;
}

function onSubmit(fn: () => void) {
    return (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        fn();
    };
}

class SignupForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            dateOfBirth: formatDobDisplay(props.signup?.customer_details.date_of_birth),
            firstName: props.signup?.customer_details.first_name ?? '',
            lastName: props.signup?.customer_details.last_name ?? '',
            email: props.signup?.customer_details.email ?? '',
            mobile: props.signup?.customer_details.mobile ?? '',
            showSms: props.authenticationMethod === 'sms' ?? true,
            agree: false,
        };

        this.onSignup = this.onSignup.bind(this);
    }

    toggleAuthMethod = (authMethod: AuthenticationMethod) => {
        this.props.setAuthenticationMethod(authMethod);
        this.setState({ showSms: authMethod === 'sms' });
    };

    async onSignup() {
        let request = {
            customer_details: {
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                date_of_birth: formatDob(this.state.dateOfBirth),
                mobile: this.state.mobile,
                email: this.state.email,
            },
            authentication_method: this.props.authenticationMethod,
        } as CustomerSignupRequestModel;
        await this.props.customerSignup(request);

        if (!this.props.error) {
            this.props.setCustomerSignupStep(CUSTOMER_SIGNUP_STEP.THREE);
        }
    }

    tryLogin = (isMobile: boolean) => {
        let query;
        if (isMobile) {
            query = querystring.stringify({ mobile: this.state.mobile });
        } else {
            query = querystring.stringify({ email: this.state.email });
        }
        return (
            <React.Fragment>
                {isMobile ? 'Mobile phone number' : 'Email'} already taken. Please{' '}
                <Link to={`/login?${query}`}>login</Link> using this {isMobile ? 'phone number' : 'email address'}.
            </React.Fragment>
        );
    };

    render() {
        const { error, requesting, authenticationMethod, submitRef, disabled } = this.props;
        const { firstName, lastName, mobile, email, dateOfBirth, agree, showSms } = this.state;

        const isDisabled =
            requesting ||
            !firstName ||
            !lastName ||
            !isValidDob(dateOfBirth) ||
            (authenticationMethod === 'email' && !isValidEmail(email)) ||
            (authenticationMethod === 'sms' && (!mobile || !agree));

        disabled(isDisabled);

        return (
            <React.Fragment>
                <Form onSubmit={onSubmit(this.onSignup)} noValidate>
                    <FormGroup>
                        <Label>First Name</Label>
                        <Input
                            id={'input-fname'}
                            key={'input-fname'}
                            type="text"
                            placeholder="Your First Name"
                            autoComplete="given-name"
                            value={this.state.firstName}
                            onChange={(e) => this.setState({ firstName: e.currentTarget.value })}
                            invalid={(isPropertyError('customer_details.first_name', error) ||
                                isPropertyError('first_name', error))}
                        />
                        <FormFeedback>
                            {error &&
                                (isPropertyError('customer_details.first_name', error) || 
                                    isPropertyError('first_name', error)) &&
                                getErrorMessage(error, null)}
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label>Last Name</Label>
                        <Input
                            id={'input-lname'}
                            type="text"
                            placeholder="Your Last Name"
                            autoComplete="family-name"
                            value={this.state.lastName}
                            onChange={(e) => this.setState({ lastName: e.currentTarget.value })}
                            invalid={(isPropertyError('customer_details.last_name', error) ||
                                isPropertyError('last_name', error))}
                        />
                        <FormFeedback>
                            {error &&
                                (isPropertyError('customer_details.last_name', error) ||
                                    isPropertyError('last_name', error)) &&
                                getErrorMessage(error, null)}
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label>Date of Birth</Label>
                        <Input
                            id={'input-dob'}
                            type="text"
                            placeholder={'11/30/1990'}
                            autoComplete="dob"
                            value={this.state.dateOfBirth}
                            onChange={(e) => {
                                this.setState({ dateOfBirth: e.currentTarget.value });
                            }}
                            invalid={
                                (isPropertyError('customer_details.date_of_birth', error) ||
                                    isPropertyError('date_of_birth', error)) ||
                                (dateOfBirth !== '' && !isValidDob(dateOfBirth))
                            }
                        />
                        <FormFeedback>
                            {(error &&
                                (isPropertyError('customer_details.date_of_birth', error) ||
                                    isPropertyError('date_of_birth', error)) &&
                                getErrorMessage(error, null)) ||
                                (dateOfBirth && !isValidDob(dateOfBirth) && 'Date must be in MM/DD/YYYY format')}
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup inline>
                        <Label>Preferred Contact Method</Label>
                        <div className="radio">
                            <label className={'contact-method'}>
                                <input
                                    type="radio"
                                    value="SMS"
                                    checked={authenticationMethod === 'sms'}
                                    onChange={() => {
                                        this.toggleAuthMethod('sms');
                                    }}
                                />
                                SMS
                            </label>
                            <label className={'contact-method'}>
                                <input
                                    type="radio"
                                    value="Email"
                                    checked={authenticationMethod === 'email'}
                                    onChange={() => {
                                        this.toggleAuthMethod('email');
                                    }}
                                />
                                Email
                            </label>
                        </div>
                        {showSms ? (
                            <FormGroup>
                                <PhoneNumberInput
                                    placeholder="Enter mobile phone number"
                                    value={this.state.mobile}
                                    isInvalid={error !== null &&
                                        (isPropertyError('customer_details.mobile', error) ||
                                            isPropertyError('mobile', error))}
                                    onChange={(mobile: string) => this.setState({ mobile })}
                                />
                                <FormFeedback>
                                    {error &&
                                        (isPropertyError('customer_details.mobile', error) ||
                                            isPropertyError('mobile', error)) &&
                                        getErrorMessage(error, null)}
                                </FormFeedback>
                            </FormGroup>
                        ) : (
                            <FormGroup>
                                <Input
                                    id={'input-email'}
                                    key={'input-email'}
                                    type="email"
                                    placeholder="Enter email address"
                                    autoComplete="email"
                                    value={this.state.email}
                                    onChange={(e) => this.setState({ email: e.currentTarget.value })}
                                    invalid={isPropertyError('customer_details.email', error) ||
                                        isPropertyError('email', error)}
                                />
                                <FormFeedback>
                                    {(error &&
                                        (isPropertyError('customer_details.email', error) 
                                            || isPropertyError('email', error)) &&
                                        getErrorMessage(error, null)) ||
                                        (email && !isValidEmail(email) && 'Invalid email')}
                                </FormFeedback>
                            </FormGroup>
                        )}
                    </FormGroup>
                    <FormGroup inline>
                        {showSms && (
                            <Label htmlFor="sms-opt-in" className="d-block text-nowrap">
                                <input
                                    type="checkbox"
                                    name="sms-opt-in"
                                    className={`mb-1`}
                                    data-sh="sms-opt-in"
                                    checked={this.state.agree}
                                    onChange={(e) => this.setState({ agree: e.target.checked })}
                                />
                                <span className="text-wrap align-top ml-2">
                                    I consent to receiving SMS paylinks and 2FA from Stronghold. For more information,
                                    please read our{' '}
                                    <a href="https://stronghold.co/legal" target="_blank" rel="noopener noreferrer">
                                        Terms of Service
                                    </a>
                                    {' and '}
                                    <a
                                        href="https://stronghold.co/privacy-policy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </a>
                                    .
                                </span>
                            </Label>
                        )}
                    </FormGroup>
                    <button ref={submitRef} type={'submit'} disabled={isDisabled} style={{ display: 'none' }} />
                </Form>
            </React.Fragment>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    error: state.error?.error,
    requesting: state.customer.requesting,
    authenticationMethod: state.customer.authenticationMethod,
    signup: state.customer.signup,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            setAuthenticationMethod: setAuthenticationMethod,
            setCustomerSignupStep: setCustomerSignupStep,
            customerSignup: signupAction,
            resetCustomerSignup: () => {
                dispatch({
                    type: 'SET_CUSTOMER_SIGNUP',
                    payload: null,
                });
            },
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
