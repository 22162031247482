import {AppThunkAction} from "../configureStore";
import {ApplicationAction} from "../actions";
import {client, RetryAddPaymentSourceModel} from "../../api";

export function getRetryPaymentSourceLinkConfigAction(
    retryModel?: RetryAddPaymentSourceModel,
) : AppThunkAction<ApplicationAction> {
    return async(dispatch, getState) => {
        dispatch({ type: 'SET_PAYMENT_SOURCE_REQUESTING', payload: true });

        try {
            const config = await client.getRetryPaymentSourceLinkConfig(retryModel);
            dispatch({
                type: 'SET_PAYMENT_SOURCE_REQUESTING',
                payload: false,
            });
            
            dispatch({
                type: 'SET_UPDATE_PAYMENT_SOURCE_CONFIG',
                payload: config.result,
            });
        } catch (error) {
            dispatch({
                type: 'SET_ERROR',
                payload: error,
            });
            
            dispatch({
                type: 'SHOW_ERROR',
                payload: true,
            })
        }
    }
}