import {AppThunkAction} from "../configureStore";
import {ApplicationAction} from "../actions";
import {client} from "../../api";

export function setProcessingFee(): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        dispatch({type: 'SET_TRANSACTION_REQUESTING', payload: true});
        const chargeAmount = getState().dropin.query.charge?.amount || 0;
        const response = await client.getProcessingFeeValue(chargeAmount);
        dispatch({type: 'SET_TRANSACTION_REQUESTING', payload: false});
        dispatch({type: 'SET_PROCESSING_FEE', payload: response.result.payment_processing_fee});
    };
}