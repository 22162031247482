import React from 'react';
import DropinContent from './DropinContent';
import DropinBody from './DropinBody';
import CoBrand from './CoBrand';
import { Spinner } from 'reactstrap/lib';

export default class DropinLoading extends React.PureComponent {
    render() {
        return (
            <DropinContent hasExitButton={false}>
                <DropinBody className="d-flex flex-column">
                    <CoBrand />
                    <div className="flex-fill d-flex justify-content-center align-items-center">
                        <Spinner type="grow" color="primary" />
                    </div>
                </DropinBody>
            </DropinContent>
        );
    }
}
