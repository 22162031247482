import React from 'react';
import notifier from '../dropin/notifier';

export class ExitButton extends React.PureComponent {
    render() {
        return (
            <div className="exit">
                <button className="exit-button" onClick={() => notifier.exit()} />
            </div>
        );
    }
}
