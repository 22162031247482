import { Charge, Tip } from '@stronghold/pay-dropin';
import {AddChargeConfig, AddTipConfig} from '../../api';

export enum ADD_CHARGE_STEP {
    ONE = 'one',
    SUCCESS = 'success',
    LOGIN_REQUIRED = 'login_required',
    UPDATE = 'update',
}

export enum ADD_TIP_STEP {
    ONE = 'one',
    SUCCESS = 'success',
    LOGIN_REQUIRED = 'login_required',
    UPDATE = 'update',
}

export interface TransactionState {
    addChargeStep: ADD_CHARGE_STEP | null;
    addChargeConfig: AddChargeConfig | null;
    addTipStep: ADD_TIP_STEP | null;
    addTipConfig: AddTipConfig | null;
    requesting: boolean;
    charge: Charge | null;
    tip: Tip | null;
    creditValue: number | null;
    processingFee: number | null;
}
