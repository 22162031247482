import React from 'react';

const HomePage = () => (
    <div className="container text-center py-3">
        <h3>
            Hello! See <a href="https://docs.strongholdpay.com">https://docs.strongholdpay.com</a> for more information.
        </h3>
    </div>
);

export default HomePage;
