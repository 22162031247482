import React from 'react';
import {connect, MapStateToPropsParam} from 'react-redux';
import CoBrand from './CoBrand';
import DropinBody from './DropinBody';
import DropinContent from './DropinContent';
import DropinFooter from './DropinFooter';
import PaymentAuthorizationAggreement from './PaymentAuthorizationAggreement';
import {ApplicationState} from '../store';
import * as selectors from '../store/selectors';
import {bindActionCreators, Dispatch} from 'redux';
import Loader from './Loader';
import Button from 'reactstrap/lib/Button';

interface StateProps {
    totalAmount: string;
    subAmount: string | null;
    convenienceFee: string | null;
    tipAmount: string | null;
    creditsAmount: string | null;
}

interface OwnProps {
    requesting: boolean;
    onClick: () => void;
}

interface DispatchProps {}

type Props = StateProps & OwnProps & DispatchProps;

class AddChargeStepOne extends React.PureComponent<Props> {
    render() {
        return (
            <DropinContent>
                <DropinBody>
                    <CoBrand />
                    <div className="text-primary display-4" data-sh="amount">
                        {this.props.totalAmount}
                    </div>

                    {this.props.subAmount ? (
                        <React.Fragment>
                            <hr />
                            <small className="d-flex justify-content-between mb-1">
                                <div className="text-muted">Subtotal (+ tax)</div>
                                <div className="text-muted" data-sh="sub-amount">
                                    {this.props.subAmount}
                                </div>
                            </small>
                        </React.Fragment>
                    ) : null}

                    {this.props.convenienceFee ? (
                        <React.Fragment>
                            <small className="d-flex justify-content-between mb-1">
                                <div className="text-muted">Convenience Fee</div>
                                <div className="text-muted" data-sh="convenience-fee-amount">
                                    {this.props.convenienceFee}
                                </div>
                            </small>
                        </React.Fragment>
                    ) : null}

                    {this.props.tipAmount ? (
                        <React.Fragment>
                            <small className="d-flex justify-content-between mb-1">
                                <div className="text-muted">Tip</div>
                                <div className="text-muted" data-sh="tip-amount">
                                    {this.props.tipAmount}
                                </div>
                            </small>
                        </React.Fragment>
                    ) : null}

                    {this.props.creditsAmount ? (
                        <React.Fragment>
                            <small className="d-flex justify-content-between mb-1">
                                <div className="text-muted">Credit Applied</div>
                                <div className="text-muted" data-sh="credits-amount">
                                    ({this.props.creditsAmount})
                                </div>
                            </small>
                        </React.Fragment>
                    ) : null}
                </DropinBody>
                <DropinFooter>
                    <Button
                        id="pay-button"
                        color="primary"
                        disabled={this.props.requesting}
                        block
                        onClick={this.props.onClick}
                    >
                        {this.props.requesting ? <Loader color="light" size="sm" /> : 'Pay'}
                    </Button>
                    <PaymentAuthorizationAggreement amount={this.props.totalAmount} />
                </DropinFooter>
            </DropinContent>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state: ApplicationState) => {
    const addConvenienceFee = state.transaction.addChargeConfig?.add_convenience_fee || false;
    let chargeAmount = state.dropin.query?.charge?.amount || 0;

    const convenienceFee = state.transaction.addChargeConfig?.convenience_fee || 0;
    // If Treez or Jane and not using paylinks, add the convenience fee to the total amount.
    if (addConvenienceFee && state.dropin.query?.payLinkId === null) {
        chargeAmount += convenienceFee;
    }

    const processingFee = state.transaction.processingFee || 0;
    // Combine the convenience fee and processing fee to show a single fee to the user
    const combinedFees = convenienceFee + processingFee;
    const tipAmount = state.dropin.query?.tip?.amount;
    let creditsAmount = state.transaction.creditValue;

    return {
        totalAmount: selectors.toCurrency(chargeAmount + (tipAmount || 0) - (creditsAmount || 0) + processingFee) || '',
        subAmount: convenienceFee ? selectors.toCurrency(chargeAmount - convenienceFee) : null,
        convenienceFee: combinedFees ? selectors.toCurrency(combinedFees) : null,
        tipAmount: selectors.toCurrency(tipAmount),
        creditsAmount: creditsAmount ? selectors.toCurrency(creditsAmount) : null,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddChargeStepOne);
