import React, { createRef } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { loginAction, setCustomerLoginStep } from '../store/actionCreators/findCustomer';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState, AuthenticationMethod, CUSTOMER_LOGIN_STEP, CustomerLoginRequestModel } from '../store';
import DropinContent from './DropinContent';
import DropinBody from './DropinBody';
import CoBrand from './CoBrand';
import DropinFooter from './DropinFooter';
import Button from 'reactstrap/lib/Button';
import Loader from './Loader';
import VerificationCode from './VerificationCode';
import CustomerFooter from './CustomerFooter';

interface StateProps {
    merchantDisplayName: string;
    requesting: boolean;
    loginRequest: CustomerLoginRequestModel | null;
    authenticationMethod: AuthenticationMethod;
}

interface DispatchProps {
    setCustomerLoginStep: typeof setCustomerLoginStep;
    resendToken: typeof loginAction;
}

interface State {
    disabled: boolean;
}

type Props = StateProps & DispatchProps;
class CustomerLoginStepTwo extends React.PureComponent<Props> {
    public state: State = {
        disabled: true,
    };

    submitRef = createRef<HTMLInputElement>();

    onResend = async () => {
        const { loginRequest } = this.props;
        if (!loginRequest) {
            return;
        }

        try {
            await this.props.resendToken(loginRequest);
        } catch (error) {
            // Error
        }
    };

    toggleVerifyButton = (disabled: boolean) => {
        this.setState({ disabled: disabled });
    };

    render() {
        const { loginRequest, requesting, authenticationMethod } = this.props;
        const { disabled } = this.state;

        if (!loginRequest || !loginRequest.username) {
            return null;
        }

        let content = (
            <VerificationCode
                value={loginRequest.username}
                isResending={requesting}
                onResend={this.onResend}
                authenticationMethod={authenticationMethod}
                onSuccess={() => this.props.setCustomerLoginStep(CUSTOMER_LOGIN_STEP.SUCCESS)}
                submitRef={this.submitRef}
                disabled={this.toggleVerifyButton}
            />
        );

        return (
            <DropinContent>
                <DropinBody>
                    <CoBrand />
                    <div className="overflow-auto" style={{ maxHeight: 280 }}>
                        {content}
                    </div>
                </DropinBody>
                <DropinFooter>
                    <Button
                        id="back-button"
                        color="primary"
                        disabled={requesting}
                        block
                        onClick={() => this.props.setCustomerLoginStep(CUSTOMER_LOGIN_STEP.ONE)}
                    >
                        {requesting ? <Loader color="light" size="sm" /> : 'Back'}
                    </Button>
                    <Button
                        id="customer-verify-button"
                        color="primary"
                        disabled={requesting || disabled}
                        block
                        onClick={() => this.submitRef.current?.click()}
                    >
                        {requesting ? <Loader color="light" size="sm" /> : 'Verify'}
                    </Button>
                    <CustomerFooter />
                </DropinFooter>
            </DropinContent>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            setCustomerLoginStep,
            resendToken: loginAction,
        },
        dispatch,
    );

const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state: ApplicationState) => ({
    merchantDisplayName: state.dropin.merchant?.display_name || '',
    requesting: state.customer.requesting,
    loginRequest: state.customer.login,
    authenticationMethod: state.customer.authenticationMethod,
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLoginStepTwo);
