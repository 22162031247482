import React from 'react';
import Spinner from 'reactstrap/lib/Spinner';

export default class Loader extends React.PureComponent<{
    size: 'sm' | 'md';
    color: 'primary' | 'light';
    type?: 'grow' | 'spinner';
}> {
    render() {
        const isSpinner = this.props.type && this.props.type === 'spinner';
        return <Spinner type={isSpinner ? undefined : 'grow'} size={this.props.size} color={this.props.color} />;
    }
}
