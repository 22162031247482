import React, { createRef } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { setCustomerSignupStep, signupAction } from '../store/actionCreators/findCustomer';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState, AuthenticationMethod, CUSTOMER_SIGNUP_STEP, CustomerSignupRequestModel } from '../store';
import DropinContent from './DropinContent';
import DropinBody from './DropinBody';
import CoBrand from './CoBrand';
import DropinFooter from './DropinFooter';
import Button from 'reactstrap/lib/Button';
import Loader from './Loader';
import VerificationCode from './VerificationCode';
import CustomerFooter from './CustomerFooter';

interface StateProps {
    merchantDisplayName: string;
    requesting: boolean;
    signupRequest: CustomerSignupRequestModel | null;
    authenticationMethod: AuthenticationMethod;
}

interface DispatchProps {
    setCustomerSignupStep: typeof setCustomerSignupStep;
    resendToken: typeof signupAction;
}

interface State {
    disabled: boolean;
}

type Props = StateProps & DispatchProps;
class CustomerSignupStepThree extends React.PureComponent<Props> {
    public state: State = {
        disabled: true,
    };

    submitRef = createRef<HTMLInputElement>();

    onResend = async () => {
        const { signupRequest } = this.props;
        if (!signupRequest) {
            return;
        }

        try {
            await this.props.resendToken(signupRequest);
        } catch (error) {
            // Error
        }
    };

    toggleVerifyButton = (disabled: boolean) => {
        this.setState({ disabled: disabled });
    };

    render() {
        const { signupRequest, requesting, authenticationMethod } = this.props;
        const { disabled } = this.state;

        if (!signupRequest || (!signupRequest.customer_details.mobile && !signupRequest.customer_details.email)) {
            return null;
        }

        let content = (
            <VerificationCode
                value={
                    authenticationMethod === 'sms'
                        ? signupRequest.customer_details.mobile!
                        : signupRequest.customer_details.email!
                }
                isResending={requesting}
                onResend={this.onResend}
                authenticationMethod={authenticationMethod}
                onSuccess={() => this.props.setCustomerSignupStep(CUSTOMER_SIGNUP_STEP.SUCCESS)}
                submitRef={this.submitRef}
                disabled={this.toggleVerifyButton}
            />
        );

        return (
            <DropinContent>
                <DropinBody>
                    <CoBrand />
                    <div className="overflow-auto" style={{ maxHeight: 280 }}>
                        {content}
                    </div>
                </DropinBody>
                <DropinFooter>
                    <Button
                        id="back-button"
                        color="primary"
                        disabled={requesting}
                        block
                        onClick={() => this.props.setCustomerSignupStep(CUSTOMER_SIGNUP_STEP.TWO)}
                    >
                        {requesting ? <Loader color="light" size="sm" /> : 'Back'}
                    </Button>
                    <Button
                        id="customer-verify-button"
                        color="primary"
                        disabled={requesting || disabled}
                        block
                        onClick={() => this.submitRef.current?.click()}
                    >
                        {requesting ? <Loader color="light" size="sm" /> : 'Verify'}
                    </Button>
                    <CustomerFooter />
                </DropinFooter>
            </DropinContent>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            setCustomerSignupStep,
            resendToken: signupAction,
        },
        dispatch,
    );

const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state: ApplicationState) => ({
    merchantDisplayName: state.dropin.merchant?.display_name || '',
    requesting: state.customer.requesting,
    signupRequest: state.customer.signup,
    authenticationMethod: state.customer.authenticationMethod,
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSignupStepThree);
