import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import AddTipStepOne from '../../components/AddTipStepOne';
import PaymentSucceeded from '../../components/PaymentSucceeded';
import { ApplicationState, getAddTipConfigAction, createTipAction, ADD_TIP_STEP } from '../../store';
import { ApplicationAction } from '../../store/actions';
import { bindActionCreators, Dispatch } from 'redux';
import notifier from '../../dropin/notifier';
import UpdatePaymentSourceStepOne from '../../components/UpdatePaymentSourceStepOne';
import AddOrUpdatePaymentSourceStep from '../../components/AddOrUpdatePaymentSourceStep';

interface StateProps {
    requesting: boolean;
    step: ADD_TIP_STEP | null;
    skipPayScreen: boolean;
    skipSuccessScreen: boolean;
}

interface DispatchProps {
    getAddTipConfigAction: typeof getAddTipConfigAction;
    createTipAction: typeof createTipAction;
    setStep: (step: ADD_TIP_STEP) => void;
}

type Props = StateProps & DispatchProps;

class AddTipPage extends React.PureComponent<Props> {
    componentDidMount() {
        this.props.getAddTipConfigAction();
    }

    componentDidUpdate(prev: Props) {
        if (prev.step === this.props.step) {
            return;
        }

        if (this.props.step === ADD_TIP_STEP.ONE) {
            if (this.props.skipPayScreen) {
                this.props.createTipAction();
            }
        }

        if (this.props.step === ADD_TIP_STEP.SUCCESS) {
            if (this.props.skipSuccessScreen) {
                notifier.exit();
            }
        }
    }

    render() {
        if (this.props.step === ADD_TIP_STEP.ONE) {
            if (this.props.skipPayScreen) {
                return null;
            }
            return <AddTipStepOne requesting={this.props.requesting} onClick={() => this.props.createTipAction()} />;
        } else if (this.props.step === ADD_TIP_STEP.LOGIN_REQUIRED) {
            return (
                <UpdatePaymentSourceStepOne
                    showLoading={!this.props.skipPayScreen}
                    onClick={() => this.props.setStep(ADD_TIP_STEP.UPDATE)}
                />
            );
        } else if (this.props.step === ADD_TIP_STEP.UPDATE) {
            return <AddOrUpdatePaymentSourceStep mode="update" onUpdate={() => this.props.setStep(ADD_TIP_STEP.ONE)} />;
        } else if (this.props.step === ADD_TIP_STEP.SUCCESS) {
            if (this.props.skipPayScreen) {
                return null;
            }
            return <PaymentSucceeded onClick={() => notifier.exit()} type="tip" />;
        } else {
            return null;
        }
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state: ApplicationState) => ({
    step: state.transaction.addTipStep,
    requesting: state.transaction.requesting,
    skipPayScreen: state.dropin.query.skipPayScreen,
    skipSuccessScreen: state.dropin.query.skipSuccessScreen,
});

const mapDispatchToProps = (dispatch: Dispatch<ApplicationAction>) =>
    bindActionCreators(
        {
            getAddTipConfigAction,
            createTipAction,
            setStep: (step: ADD_TIP_STEP) =>
                dispatch({
                    type: 'SET_ADD_TIP_STEP',
                    payload: step,
                }),
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddTipPage);
