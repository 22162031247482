import React from 'react';
import {connect, MapStateToPropsParam} from 'react-redux';
import {
    ApplicationState,
    finicityListAccountsAction, getSubdomain, isPlaidOauth,
    plaidAddPaymentSourceSuccessAction,
    yodleeAddPaymentSourceSuccessAction
} from '../store';
import {bindActionCreators, Dispatch} from 'redux';
import {AGGREGATOR_VENDOR, client, UpdatePaymentSourceConfig} from '../api';
import * as Sentry from '../sentry';
import notifier from '../dropin/notifier';
import DropinContent from './DropinContent';
import DropinLoading from './DropinLoading';
import {setTag, Severity} from '@sentry/react';
import * as DataDog from "../datadog";
import {StatusType} from "@datadog/browser-logs";
import {PlaidLinkOnSuccessMetadata, PlaidLinkOptions} from "react-plaid-link";

interface StateProps {
    aggregatorVendor: AGGREGATOR_VENDOR;
    plaidLinkToken: string;
    plaidAccountId: string | null;
    yodleeAccessToken: string;
    yodleeUrl: string;
    yodleeProviderAccountId: number | null;
    finicityConnectUrl: string;
    requesting: boolean;
}

type OwnProps =
    | {
          mode: 'retry_login'; 
      }
    | {
          mode: 'add';
      }
    | {
          mode: 'update';
          onUpdate: () => void;
      };

interface DispatchProps {
    plaidAddPaymentSourceSuccessAction: typeof plaidAddPaymentSourceSuccessAction;
    yodleeAddPaymentSourceSuccessAction: typeof yodleeAddPaymentSourceSuccessAction;
    finicityListAccountsAction: typeof finicityListAccountsAction;
}

type Props = StateProps & OwnProps & DispatchProps;

class AddOrUpdatePaymentSourceStepLink extends React.PureComponent<Props> {
    componentDidMount() {
        if (this.props.aggregatorVendor === AGGREGATOR_VENDOR.Plaid) {
            this.setupPlaid();
            if (isPlaidOauth(window.location.href)) {
                this.reinitializePlaid();
            } else {
                this.plaid();
            }
        } else if (this.props.aggregatorVendor === AGGREGATOR_VENDOR.Yodlee) {
            setTimeout(() => this.yodlee(), 100);
        } else if (this.props.aggregatorVendor === AGGREGATOR_VENDOR.Finicity) {
            setTimeout(() => this.finicity(), 50);
        }
    }

    componentWillUnmount() {
        this.cleanPlaid();
    }

    setupPlaid = () => {
        // Add specific class to the body for plaid screen
        document.body.classList.add('dropin-overlay');
    };

    cleanPlaid = () => {
        // Add specific class to the body for plaid screen
        document.body.classList.remove('dropin-overlay');
    };

    async plaid() {
        const createConfig: PlaidLinkOptions = {
            token: this.props.plaidLinkToken,
            // TODO: Remove once the lib is up to date
            clientName: (null as unknown) as string,
            env: (null as unknown) as string,
            product: (null as unknown) as [],
            // TODO: End
            onSuccess: (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
                setTag("additional.data.link_session_id", metadata?.link_session_id);
                setTag("additional.data.institution", metadata?.institution?.name);
                
                Sentry.addSentryBreadcrumb(`[Plaid] Successfully linked an account`);
                DataDog.addDataDogLog('[Plaid] Successfully linked an account',
                    { metadata }, StatusType.info);
                
                this.cleanPlaid();

                if (this.props.mode === 'add' || this.props.mode === 'retry_login') {
                    // when mode == 'retry_login' this.props.plaidAccountId should be passed in
                    this.props.plaidAddPaymentSourceSuccessAction(public_token, metadata, this.props.plaidAccountId);
                } else {
                    this.props.onUpdate();
                }
            },
            onExit: (err, metadata) => {
                this.cleanPlaid();

                setTag("additional.data.link_session_id", metadata?.link_session_id);
                setTag("additional.data.request_id", metadata?.request_id);
                setTag("additional.data.institution", metadata?.institution?.name);
                setTag("additional.data.status", metadata?.status);
                
                if (err) {
                    Sentry.captureSentryException(new Error('[Plaid] Fails to link a bank account'), {
                        extra: err,
                    });
                    DataDog.addDataDogLog('[Plaid] Fails to link a bank account',
                        { err, metadata }, StatusType.error);
                }
                notifier.exit();
            },
        };

        const handler = window.Plaid.create(createConfig);

        handler.open();
    }

    async reinitializePlaid() {
        let cacheKey = getSubdomain(window.location.href);
        let cachedValue = await client.getPlaidLinkTokenCacheValue(cacheKey!);
        
        const createConfig: PlaidLinkOptions = {
            token: cachedValue.result.link_token,
            receivedRedirectUri: window.location.href,
            clientName: (null as unknown) as string,
            env: (null as unknown) as string,
            product: (null as unknown) as [],
            onSuccess: (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
                this.cleanPlaid();
                this.props.plaidAddPaymentSourceSuccessAction(public_token, metadata, this.props.plaidAccountId);
            },
            onExit: (err, metadata) => {
                this.cleanPlaid();
                notifier.exit();
            },
        };

        const handler = window.Plaid.create(createConfig);
        handler.open();
    }

    yodlee() {
        let isExitCalled = false;
        let onSuccessData: YodleeOnSuccessCallbackData | null = null;

        window.fastlink.open(
            {
                fastLinkURL: this.props.yodleeUrl,
                accessToken: `Bearer ${this.props.yodleeAccessToken}`,
                params: {
                    configName: 'Verification',
                    ...(this.props.mode === 'add'
                        ? {}
                        : {
                              flow: 'edit',
                              providerAccountId: this.props.yodleeProviderAccountId || 0,
                          }),
                },
                // IMPORTANT: Make it works on mobile
                forceIframe: true,
                onSuccess: (data) => {
                    // We log the Yodlee error but this error is not necessarily blocking
                    // It can be cause by a wrong login or timeout. In those cases, Yodlee is already informing the customer.
                    setTag("additional.data.additionalStatus", data?.additionalStatus);
                    setTag("additional.data.bankName", data?.bankName);
                    setTag("additional.data.providerAccountId", data?.providerAccountId);
                    setTag("additional.data.providerId", data?.providerId);
                    setTag("additional.data.status", data?.status);
                    setTag("additional.data.requestId", data?.requestId);
                    
                    Sentry.addSentryBreadcrumb(`[Yodlee] Successfully linked an account`, { data });
                    DataDog.addDataDogLog('[Yodlee] Successfully linked an account',
                        { data }, StatusType.info);
                    
                    onSuccessData = data;
                },
                onClose: (data) => {
                    Sentry.addSentryBreadcrumb(`[Yodlee] Exit Fastlink link an account`, { data });
                    DataDog.addDataDogLog('[Yodlee] Exit Fastlink link an account',
                        { data }, StatusType.info);

                    if (this.props.mode === 'add' || this.props.mode === 'retry_login') {
                        // We check if the exit call has already been called
                        if (isExitCalled) {
                            return;
                        }
                        isExitCalled = true;

                        // Make sure we exit if the customer quit
                        if (
                            !onSuccessData ||
                            !data.sites ||
                            data.sites.length === 0 ||
                            data.sites[0].status === 'ACTION_ABANDONED'
                        ) {
                            notifier.exit();
                            return;
                        }

                        this.props.yodleeAddPaymentSourceSuccessAction(onSuccessData.providerAccountId, data.sites);
                    } else {
                        this.props.onUpdate();
                    }
                },
                onError: (data: YodleeOnErrorCallbackData) => {
                    // We log the Yodlee error but this error is not necessarily blocking
                    // It can be cause by a wrong login or timeout. In those cases, Yodlee is already informing the customer.
                    setTag("additional.data.additionalStatus", data?.additionalStatus);
                    setTag("additional.data.bankName", data?.bankName);
                    setTag("additional.data.providerAccountId", data?.providerAccountId);
                    setTag("additional.data.reason", data?.reason);
                    setTag("additional.data.providerId", data?.providerId);
                    setTag("additional.data.statusCode", data?.statusCode);
                    setTag("additional.data.status", data?.status);
                    setTag("additional.data.requestId", data?.requestId);
                    
                    Sentry.captureSentryEvent(`[Yodlee] Error while linking a new account`, {
                        level: Severity.Warning,
                    });
                    DataDog.addDataDogLog('[Yodlee] Error while linking a new account',
                        { level: Severity.Warning, extra: data }, StatusType.error);
                },
            },
            'container-fastlink',
        );
    }

    finicity() {
        window.finicityConnect.launch(this.props.finicityConnectUrl, {
            selector: '#finicity-connect-container',
            overlay: 'rgba(0, 0, 0, 0); border-radius: 8px; position: relative;',
            success: (data) => {
                Sentry.addSentryBreadcrumb(`[Finicity] Successfully linked an account`, { data });
                DataDog.addDataDogLog('[Finicity] Successfully linked an account',
                    { data }, StatusType.info);
                
                if (this.props.mode === 'add' || this.props.mode === 'retry_login') {
                    this.props.finicityListAccountsAction();
                } else {
                    this.props.onUpdate();
                }
            },
            cancel: () => notifier.exit(),
            error: function (err) {
                Sentry.captureSentryException(new Error('[Finicity] Fails to link a bank account'), {
                    extra: { err },
                });
                DataDog.addDataDogLog('[[Finicity] Fails to link a bank account',
                    { extra: err }, StatusType.error);
                notifier.exit();
            },
        });
    }

    render() {
        if (this.props.requesting) {
            return <DropinLoading />;
        }

        if (this.props.aggregatorVendor === AGGREGATOR_VENDOR.Plaid) {
            return <DropinContent hasModal={false} hasContainer={false} />;
        } else if (this.props.aggregatorVendor === AGGREGATOR_VENDOR.Yodlee) {
            return (
                <DropinContent hasExitButton={false} hasModal={false} containerClassName="overflow-auto">
                    <div id="container-fastlink" />
                </DropinContent>
            );
        } else if (this.props.aggregatorVendor === AGGREGATOR_VENDOR.Finicity) {
            return (
                <DropinContent hasExitButton={false} hasModal={false}>
                    <div id="finicity-connect-container" className="h-100" />
                </DropinContent>
            );
        }

        return <DropinContent hasContainer={false} hasModal={false} />;
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state: ApplicationState) => ({
    aggregatorVendor: state.paymentSource.config?.aggregator_vendor || AGGREGATOR_VENDOR.Plaid,
    plaidLinkToken: state.paymentSource.config?.plaid?.link_token || '',
    plaidAccountId: (state.paymentSource.config as UpdatePaymentSourceConfig)?.plaid?.account_id || null,
    yodleeAccessToken: state.paymentSource.config?.yodlee?.access_token || '',
    yodleeUrl: state.paymentSource.config?.yodlee?.url || '',
    yodleeProviderAccountId:
        (state.paymentSource.config as UpdatePaymentSourceConfig)?.yodlee?.provider_account_id || null,
    finicityConnectUrl: state.paymentSource.config?.finicity?.connect_url || '',
    requesting: state.paymentSource.requesting,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            plaidAddPaymentSourceSuccessAction,
            yodleeAddPaymentSourceSuccessAction,
            finicityListAccountsAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddOrUpdatePaymentSourceStepLink);
