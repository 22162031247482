import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';

export function initializeSentry({
    customerId,
    dsn,
    environment,
}: {
    dsn: string;
    environment: string;
    customerId: string;
}) {
    if (!dsn || !environment) {
        return;
    }

    Sentry.init({ dsn, environment });
    Sentry.setUser({ id: customerId });
}

export function addSentryBreadcrumb(
    message: string,
    options: { level?: Severity; data?: { [key: string]: any } } = {},
) {
    Sentry.addBreadcrumb({
        message,
        level: options.level || Sentry.Severity.Info,
        data: options.data,
    });
}

export function captureSentryEvent(
    message: string,
    options: { level?: Severity; extra?: { [key: string]: any } } = {},
) {
    Sentry.captureEvent({
        message,
        level: options.level || Sentry.Severity.Info,
        extra: options.extra,
    });
}

export function captureSentryException(error: Error, options: { extra?: { [key: string]: any } } = {}) {
    Sentry.captureException(error, { extra: options.extra });
}
