import React from 'react';
import { ExitButton } from './ExitButton';
import { isMobile } from 'react-device-detect';

interface Props {
    children?: React.ReactNode;
    hasExitButton?: boolean;
    hasContainer?: boolean;
    hasModal?: boolean;
    containerId?: string;
    containerClassName?: string;
}

interface State {
    isMobile: boolean;
}

export default class DropinContent extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isMobile,
        };
    }

    render() {
        const {
            hasExitButton = true,
            hasContainer = true,
            hasModal = true,
            containerClassName = '',
            containerId,
        } = this.props;
        const exitButton = hasExitButton ? <ExitButton /> : null;

        let children = this.props.children;

        if (hasModal) {
            children = (
                <div className="dropin-layout">
                    <div className="h-100 d-flex flex-column">{children}</div>
                </div>
            );
        }

        if (hasContainer) {
            children = (
                <div
                    id={containerId}
                    className={['dropin-container', this.state.isMobile ? 'mobile' : '', containerClassName].join(' ')}
                >
                    {exitButton}
                    {children}
                </div>
            );
        }

        return <div className="dropin-overlay">{children}</div>;
    }
}
