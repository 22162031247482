import React from 'react';
import {connect, MapStateToPropsParam} from 'react-redux';
import CoBrand from './CoBrand';
import DropinBody from './DropinBody';
import DropinContent from './DropinContent';
import DropinFooter from './DropinFooter';
import Button from 'reactstrap/lib/Button';
import DropinLoading from './DropinLoading';
import {ApplicationState, getUpdatePaymentSourceConfigAction} from '../store';
import {bindActionCreators, Dispatch} from 'redux';
import {RetryAddPaymentSourceModel, UpdatePaymentSourceConfig} from '../api';
import {ApplicationAction} from '../store/actions';
import {getRetryPaymentSourceLinkConfigAction} from "../store/actionCreators/retryPaymentSourceLink";

interface StateProps {
    merchantDisplayName: string;
    requesting: boolean;
    isReAuthenticationNeeded: boolean;
}

interface OwnProps {
    showLoading: boolean;
    onClick: () => void;
    isRetry?: boolean;
    retryModel?: RetryAddPaymentSourceModel;
}

interface DispatchProps {
    getUpdatePaymentSourceConfigAction: typeof getUpdatePaymentSourceConfigAction;
    getRetryPaymentSourceLinkConfigAction: typeof getRetryPaymentSourceLinkConfigAction;
}

type Props = StateProps & OwnProps & DispatchProps;

class UpdatePaymentSourceStepOne extends React.PureComponent<Props> {
    componentDidMount() {
        if (this.props.isRetry) {
            this.props.getRetryPaymentSourceLinkConfigAction(this.props.retryModel);
        } else {
            this.props.getUpdatePaymentSourceConfigAction(); 
        }
    }

    render() {
        if (this.props.requesting) {
            if (this.props.showLoading) {
                return <DropinLoading />;
            } else {
                return null;
            }
        }

        return (
            <DropinContent>
                <DropinBody>
                    <CoBrand />
                    <div data-sh="message">
                        For your security, your financial institution is requesting that you re-verify your details for
                        payment.
                    </div>
                </DropinBody>
                <DropinFooter>
                    <Button
                        id="update-payment-source-step-1-button"
                        color="primary"
                        disabled={this.props.requesting}
                        block
                        onClick={this.props.onClick}
                    >
                        Next
                    </Button>
                </DropinFooter>
            </DropinContent>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state: ApplicationState) => ({
    merchantDisplayName: state.dropin.merchant?.display_name || '',
    requesting: state.paymentSource.requesting,
    isReAuthenticationNeeded:
        (state.paymentSource.config as UpdatePaymentSourceConfig)?.is_re_authentication_needed || true,
});

const mapDispatchToProps = (dispatch: Dispatch<ApplicationAction>) =>
    bindActionCreators(
        {
            getUpdatePaymentSourceConfigAction,
            getRetryPaymentSourceLinkConfigAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePaymentSourceStepOne);
