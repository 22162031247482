import React from 'react';

export default class DropinBody extends React.PureComponent<{
    children: React.ReactNode;
    className?: string;
}> {
    render() {
        return <div className={['flex-fill', this.props.className || ''].join(' ')}>{this.props.children}</div>;
    }
}
