import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { AggregatorState } from '../types';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: AggregatorState = {
    finicityAccounts: [],
};

export const aggregator: Reducer<AggregatorState, ApplicationAction> = (
    state: AggregatorState = unloadedState,
    action: ApplicationAction,
) => {
    switch (action.type) {
        case 'SET_FINICITY_ACCOUNTS_ACTION':
            return {
                ...state,
                finicityAccounts: action.payload,
            };
        default:
            return state;
    }
};
