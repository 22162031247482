import React from 'react';
import {connect, MapStateToPropsParam} from 'react-redux';
import CoBrand from './CoBrand';
import DropinBody from './DropinBody';
import DropinContent from './DropinContent';
import DropinFooter from './DropinFooter';
import Button from 'reactstrap/lib/Button';
import Loader from './Loader';
import {ApplicationState, getAddPaymentSourceConfigAction} from '../store';
import {bindActionCreators, Dispatch} from 'redux';
import EndUserAgreement from './EndUserAgreement';

interface StateProps {
    merchantDisplayName: string;
    requesting: boolean;
    payLinkId: string;
}

interface OwnProps {}

interface DispatchProps {
    getAddPaymentSourceConfigAction: typeof getAddPaymentSourceConfigAction;
}

type Props = StateProps & OwnProps & DispatchProps;

class AddPaymentSourceStepOne extends React.PureComponent<Props> {
    render() {
        return (
            <DropinContent>
                <DropinBody>
                    <CoBrand />
                    <div className="mb-3">
                        <strong>Stronghold</strong>, a <strong>{this.props.merchantDisplayName}</strong> partner, will
                        take you through 3 quick steps to securely verify your details for payment.
                    </div>
                    <div className="steps-list">
                        <div className="steps-item">
                            <div className="steps-number">1.</div>
                            <div className="steps-text">Log in with your online banking credentials</div>
                        </div>
                        <div className="steps-item">
                            <div className="steps-number">2.</div>
                            <div className="steps-text">Verify your account</div>
                        </div>
                        <div className="steps-item">
                            <div className="steps-number">3.</div>
                            <div className="steps-text">Complete your payment</div>
                        </div>
                    </div>
                </DropinBody>
                <DropinFooter>
                    <Button
                        id="add-payment-source-step-1-button"
                        color="primary"
                        disabled={this.props.requesting}
                        block
                        onClick={() => this.props.getAddPaymentSourceConfigAction(this.props.payLinkId)}
                    >
                        {this.props.requesting ? <Loader color="light" size="sm" /> : 'Next'}
                    </Button>
                    <EndUserAgreement />
                </DropinFooter>
            </DropinContent>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state: ApplicationState) => ({
    merchantDisplayName: state.dropin.merchant?.display_name || '',
    requesting: state.paymentSource.requesting,
    payLinkId: state.dropin.query?.payLinkId || '',
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getAddPaymentSourceConfigAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentSourceStepOne);
