import {Reducer} from 'redux';
import {ApplicationAction} from '../actions';
import {ADD_PAYMENT_SOURCE_STEP, PaymentSourceState, UPDATE_PAYMENT_SOURCE_STEP} from '../types';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: PaymentSourceState = {
    paymentSource: null,
    addStep: ADD_PAYMENT_SOURCE_STEP.ONE,
    config: null,
    creditCardConfig: null,
    requesting: false,
    updateStep: UPDATE_PAYMENT_SOURCE_STEP.ONE,
    retryModel: null,
};

export const paymentSource: Reducer<PaymentSourceState, ApplicationAction> = (
    state: PaymentSourceState = unloadedState,
    action: ApplicationAction,
) => {
    switch (action.type) {
        case 'SET_ADD_PAYMENT_SOURCE_CONFIG':
            return {
                ...state,
                config: action.payload,
            };
        case 'SET_PAYMENT_SOURCE_REQUESTING':
            return {
                ...state,
                requesting: action.payload,
            };
        case 'SET_ADD_PAYMENT_SOURCE_STEP':
            return {
                ...state,
                addStep: action.payload,
            };
        case 'SET_UPDATE_PAYMENT_SOURCE_CONFIG':
            return {
                ...state,
                config: action.payload,
            };
        case 'SET_UPDATE_PAYMENT_SOURCE_STEP':
            return {
                ...state,
                updateStep: action.payload,
            };
        case 'SET_PAYMENT_SOURCE':
            return {
                ...state,
                paymentSource: action.payload,
            };
        case 'SET_ADD_CHARGE_CONFIG':
            return {
                ...state,
                paymentSource: action.payload.payment_source,
            };
        case 'SET_CARD_SOURCE_CONFIGURATION':
            return {
                ...state,
                creditCardConfig: action.payload,
            };
        case 'SET_RETRY_PAYMENT_SOURCE_LINK_MODEL':
            return {
                ...state,
                retryModel: action.payload,
            };
        default:
            return state;
    }
};
