import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import CoBrand from './CoBrand';
import DropinBody from './DropinBody';
import DropinContent from './DropinContent';
import DropinFooter from './DropinFooter';
import Button from 'reactstrap/lib/Button';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState, finicityAddPaymentSourceSuccessAction } from '../store';
import { ListGroup, ListGroupItem } from 'reactstrap';
import Loader from './Loader';

interface StateProps {
    finicityAccounts: FinicityAccount[];
    requesting: boolean;
}

interface OwnProps {}

interface DispatchProps {
    finicityAddPaymentSourceSuccessAction: typeof finicityAddPaymentSourceSuccessAction;
}

type Props = StateProps & OwnProps & DispatchProps;

interface State {
    accountSelected: number | null;
}

class AddPaymentSourceStepSelection extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { accountSelected: null };
    }

    render() {
        return (
            <DropinContent>
                <DropinBody>
                    <CoBrand />
                    <h5>Select an account</h5>
                    <div className="overflow-auto" style={{ maxHeight: 280 }}>
                        <ListGroup>
                            {this.props.finicityAccounts.map((el, key) => (
                                <ListGroupItem
                                    key={key}
                                    onClick={() => this.setState({ accountSelected: el.id })}
                                    active={el.id === this.state.accountSelected}
                                    disabled={this.props.requesting}
                                    action
                                    className="text-right"
                                >
                                    <div className="text-left">{el.name}</div>
                                    <small>**** {el.real_account_number_last4}</small>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </div>
                </DropinBody>
                <DropinFooter>
                    <Button
                        id="finish-button"
                        color="primary"
                        block
                        disabled={!this.state.accountSelected || this.props.requesting}
                        onClick={() =>
                            this.props.finicityAddPaymentSourceSuccessAction(this.state.accountSelected || 0)
                        }
                    >
                        {this.props.requesting ? <Loader color="light" size="sm" /> : 'Next'}
                    </Button>
                </DropinFooter>
            </DropinContent>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state: ApplicationState) => ({
    finicityAccounts: state.aggregator.finicityAccounts,
    requesting: state.paymentSource.requesting,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            finicityAddPaymentSourceSuccessAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentSourceStepSelection);
