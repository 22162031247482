import {ERROR_CODE, StrongholdPayError} from '@stronghold/pay-dropin';
import {client} from '../../api';
import notifier from '../../dropin/notifier';
import {ApplicationAction} from '../actions';
import {AppThunkAction} from '../configureStore';
import {getPaymentSourceId} from '../selectors';
import {ADD_CHARGE_STEP} from '../types';

export function getAddChargeConfigAction(): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        dispatch({ type: 'SET_TRANSACTION_REQUESTING', payload: true });

        const paymentSourceId = getPaymentSourceId(getState()) || '';
        try {
            const body = await client.getAddChargeConfig(paymentSourceId);
            dispatch({ type: 'SET_TRANSACTION_REQUESTING', payload: false });
            dispatch({ type: 'SET_ADD_CHARGE_CONFIG', payload: body.result });
            dispatch({
                type: 'SET_ADD_CHARGE_STEP',
                payload: ADD_CHARGE_STEP.ONE,
            });
            notifier.ready();
        } catch (error) {
            notifier.ready();
            dispatch({ type: 'SET_ERROR', payload: error });
            dispatch({ type: 'SHOW_ERROR', payload: true });
        }
    };
}

export function createChargeAction(): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        dispatch({ type: 'SET_TRANSACTION_REQUESTING', payload: true });

        const query = getState().dropin.query;
        const transactionState = getState().transaction;
        const addConvenienceFee = getState().transaction.addChargeConfig?.add_convenience_fee || false;
        let chargeAmount = query.charge?.amount || 0;

        // If Treez or Jane and not using paylinks, add the convenience fee to the total amount.
        if (addConvenienceFee && query.payLinkId === null) {
            chargeAmount += transactionState.addChargeConfig?.convenience_fee || 0;
        }

        try {
            const body = await client.postCharge({
                authorize_only: query.authorizeOnly,
                pay_link_id: query.payLinkId || undefined,
                charge: {
                    amount: chargeAmount,
                    payment_source_id: query.charge?.paymentSourceId || '',
                    external_id: query.charge?.externalId || undefined,
                },
                tip: query.tip
                    ? {
                          amount: query.tip.amount,
                          beneficiary_name: query.tip.beneficiaryName,
                          details: {
                              display_message: query.tip.details.displayMessage,
                              drawer_id: query.tip.details.drawerId,
                          },
                      }
                    : null,
            });
            notifier.success(body.result);
            dispatch({ type: 'SET_TRANSACTION_REQUESTING', payload: false });
            dispatch({ type: 'SET_CHARGE', payload: body.result });
            dispatch({
                type: 'SET_ADD_CHARGE_STEP',
                payload: ADD_CHARGE_STEP.SUCCESS,
            });
        } catch (error) {
            notifier.ready();
            dispatch({ type: 'SET_TRANSACTION_REQUESTING', payload: false });
            if (error instanceof StrongholdPayError) {
                if (error.code === ERROR_CODE.PAYMENT_SOURCE_LOGIN_REQUIRED) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: error,
                        notify: false,
                    });
                    dispatch({
                        type: 'SET_ADD_CHARGE_STEP',
                        payload: ADD_CHARGE_STEP.LOGIN_REQUIRED,
                    });
                    return;
                }
            }
            dispatch({ type: 'SET_ERROR', payload: error });
            dispatch({ type: 'SHOW_ERROR', payload: true });
        }
    };
}
