import { Reducer } from 'redux';
import { TransactionAction } from '../actions';
import { TransactionState } from '../types';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: TransactionState = {
    addChargeStep: null,
    addChargeConfig: null,
    addTipStep: null,
    addTipConfig: null,
    requesting: false,
    charge: null,
    tip: null,
    creditValue: null,
    processingFee: null,
};

export const transaction: Reducer<TransactionState, TransactionAction> = (
    state: TransactionState = unloadedState,
    action: TransactionAction,
) => {
    switch (action.type) {
        case 'SET_ADD_CHARGE_CONFIG':
            return {
                ...state,
                addChargeConfig: action.payload,
            };
        case 'SET_TRANSACTION_REQUESTING':
            return {
                ...state,
                requesting: action.payload,
            };
        case 'SET_ADD_CHARGE_STEP':
            return {
                ...state,
                addChargeStep: action.payload,
            };
        case 'SET_CHARGE':
            return {
                ...state,
                charge: action.payload,
            };
        case 'SET_ADD_TIP_STEP':
            return {
                ...state,
                addTipStep: action.payload,
            };
        case 'SET_TIP':
            return {
                ...state,
                tip: action.payload,
            };
        case 'SET_CREDIT_VALUE':
            return {
                ...state,
                creditValue: action.payload,
            };
        case 'SET_PROCESSING_FEE':
            return {
                ...state,
                processingFee: action.payload,
            };
        default:
            return state;
    }
};
