import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';

interface StateProps {
    merchantLogoUrl: string;
}

type Props = StateProps;

class CoBrand extends React.PureComponent<Props> {
    render() {
        return (
            <div className="mb-2 d-flex justify-content-between">
                {this.props.merchantLogoUrl ? (
                    <img
                        className="img-fluid"
                        style={{ height: 140, width: 140 }}
                        src={this.props.merchantLogoUrl}
                        alt={`${this.props.merchantLogoUrl} logo`}
                    />
                ) : null}
                <img
                    className="img-fluid"
                    style={{ height: 140, width: 140 }}
                    src="/images/stronghold-logo.png"
                    alt="Stronghold logo"
                />
            </div>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state: ApplicationState) => ({
    merchantLogoUrl: state.dropin.merchant?.logo_url || '',
});

export default connect(mapStateToProps)(CoBrand);
