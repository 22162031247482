import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState, CUSTOMER_PAGE } from '../store';
import { bindActionCreators, Dispatch } from 'redux';
import { setCustomerPage } from '../store/actionCreators/findCustomer';

interface DispatchProps {
    setCustomerPage: typeof setCustomerPage;
}

interface StateProps {
    page: CUSTOMER_PAGE;
}

type Props = DispatchProps & StateProps;

class CustomerFooter extends React.PureComponent<Props> {
    render() {
        const { setCustomerPage, page } = this.props;

        return (
            <div className="text-center">
                {page === CUSTOMER_PAGE.LOGIN ? "Don't have an account?" : 'Already have an account?'}
                <button
                    onClick={() =>
                        setCustomerPage(page === CUSTOMER_PAGE.LOGIN ? CUSTOMER_PAGE.SIGNUP : CUSTOMER_PAGE.LOGIN)
                    }
                    style={{
                        background: 'none',
                        border: 'none',
                        color: 'blue',
                        cursor: 'pointer',
                        fontSize: 'inherit',
                        padding: 5,
                    }}
                >
                    {page === CUSTOMER_PAGE.LOGIN ? 'Sign up' : 'Log in'}
                </button>
            </div>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state: ApplicationState) => ({
    page: state.customer.page,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            setCustomerPage,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(CustomerFooter);
