import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import CoBrand from './CoBrand';
import DropinBody from './DropinBody';
import DropinContent from './DropinContent';
import DropinFooter from './DropinFooter';
import Button from 'reactstrap/lib/Button';
import Loader from './Loader';
import {
    ApplicationState,
    CardSourceConfig,
    creditCardAddPaymentSourceSuccessAction,
    setCardSourceConfigSuccessAction,
} from '../store';
import { bindActionCreators, Dispatch } from 'redux';
import EndUserAgreement from './EndUserAgreement';
import { Helmet } from 'react-helmet';
import { TsepConfiguration } from '../api';

interface StateProps {
    merchantDisplayName: string;
    requesting: boolean;
    creditCardConfig: CardSourceConfig | null;
    tsepConfig: TsepConfiguration | null;
}

interface OwnProps {}

interface DispatchProps {
    setCardSourceConfigSuccessAction: typeof setCardSourceConfigSuccessAction;
    creditCardAddPaymentSourceSuccessAction: typeof creditCardAddPaymentSourceSuccessAction;
}

type Props = StateProps & DispatchProps;

interface State {
    ready: boolean;
    isAuthorizing: boolean;
}

class AddCreditCardPaymentSourceStepOne extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            ready: false,
            isAuthorizing: false,
        };
    }

    componentDidMount = async () => {
        // checks to see if the dynamically populated input is on the dom
        setInterval(() => {
            const ready = document.getElementById('tsep-cardHolderName');
            if (ready) {
                this.setState({ ready: true });
            }
        }, 10);

        (window as any).tsepHandler = async (eventType: any, event: any) => {
            if (eventType === 'TokenEvent' && event.responseCode === 'A0000') {
                const result = {
                    cardHolderName: event.cardHolderName.trim(),
                    tsepToken: event.tsepToken.trim(),
                    expirationDate: event.expirationDate.trim(),
                    cvv2: event.cvv2.trim(),
                    cardType: event.cardType.trim(),
                    maskedCardNumber: event.maskedCardNumber.trim(),
                    zip: event.zipCode.trim(),
                } as CardSourceConfig;
                await this.props.setCardSourceConfigSuccessAction(result);
            }
        };
    };

    componentDidUpdate = async (prevProps: Readonly<Props>) => {
        const { creditCardConfig } = this.props;
        const { isAuthorizing } = this.state;
        if (isAuthorizing && creditCardConfig && prevProps.creditCardConfig !== creditCardConfig) {
            await this.props.creditCardAddPaymentSourceSuccessAction(creditCardConfig);
            this.setState({ isAuthorizing: false });
        }
    };

    onSubmit = async () => {
        this.setState({ isAuthorizing: true });
    };

    render() {
        const { ready } = this.state;
        const { tsepConfig } = this.props;

        return (
            <DropinContent>
                {tsepConfig && (
                    <Helmet>
                        <script src={`${tsepConfig.script_path}${tsepConfig.manifest}`}></script>
                        <script
                            dangerouslySetInnerHTML={{ __html: 'function tsepHandler(eventType, event){}' }}
                        ></script>
                    </Helmet>
                )}
                <DropinBody>
                    <CoBrand />
                    <div className="mb-3">
                        <strong>Stronghold</strong>, a <strong>{this.props.merchantDisplayName}</strong> partner,
                        requires you to fill in the form below to authorize your credit card.
                    </div>

                    {!ready ? (
                        <div className="my-5 py-5">
                            <Loader type={'spinner'} size={'md'} color={'primary'} />
                        </div>
                    ) : (
                        <p>
                            <strong>Card Information</strong>
                        </p>
                    )}

                    <div className="cardDetails">
                        <form method="POST" id="payment-form">
                            <div
                                className="tsep-cardHolderName"
                                id="tsep-cardHolderNameDiv"
                                data-validate-name="Y"
                            ></div>
                            <div
                                className="tsep-cardNum"
                                id="tsep-cardNumDiv"
                                data-auto-formatting="Y"
                                data-validate-cc="Y"
                                data-detect-card-type="Y"
                            ></div>
                            <div
                                className="tsep-datePicker"
                                id="tsep-datepickerDiv"
                                data-validate-expiry-date="Y"
                            ></div>
                            <div className="tsep-cvv2" id="tsep-cvv2Div" data-validate-cvv2="Y"></div>
                            <div className="tsep-zipCode" id="tsep-zipCodeDiv" data-validate-zipcode="Y"></div>
                        </form>
                    </div>
                </DropinBody>
                {ready && (
                    <DropinFooter>
                        <Button
                            id="add-payment-source-credit-card-step-1-button"
                            color="primary"
                            disabled={this.props.requesting || this.state.isAuthorizing}
                            block
                            onClick={() => this.onSubmit()}
                        >
                            {this.props.requesting || this.state.isAuthorizing ? (
                                <Loader color="light" size="sm" />
                            ) : (
                                'Add'
                            )}
                        </Button>
                        <EndUserAgreement label="Add" />
                    </DropinFooter>
                )}
            </DropinContent>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state: ApplicationState) => ({
    merchantDisplayName: state.dropin.merchant?.display_name || '',
    requesting: state.paymentSource.requesting,
    creditCardConfig: state.paymentSource.creditCardConfig,
    tsepConfig: state.dropin.tsep ? state.dropin.tsep : null,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            creditCardAddPaymentSourceSuccessAction,
            setCardSourceConfigSuccessAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddCreditCardPaymentSourceStepOne);
