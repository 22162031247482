import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import CoBrand from './CoBrand';
import DropinBody from './DropinBody';
import DropinContent from './DropinContent';
import DropinFooter from './DropinFooter';
import Button from 'reactstrap/lib/Button';
import Loader from './Loader';
import { ApplicationState, PAYMENT_SOURCE_METHOD, setAddPaymentSourceMethodAction } from '../store';
import { bindActionCreators, Dispatch } from 'redux';

interface StateProps {
    merchantDisplayName: string;
    requesting: boolean;
}

interface OwnProps {}

interface DispatchProps {
    setAddPaymentSourceMethodAction: typeof setAddPaymentSourceMethodAction;
}

type Props = StateProps & OwnProps & DispatchProps;

class SelectPaymentSourceMethodStep extends React.PureComponent<Props> {
    render() {
        return (
            <DropinContent>
                <DropinBody>
                    <CoBrand />
                    <div className="mb-3">
                        <strong>Stronghold</strong>, a <strong>{this.props.merchantDisplayName}</strong> partner,
                        provides two options to pay for your order.
                    </div>
                    <div className="mb-1">Please select your preferred payment method below.</div>
                </DropinBody>
                <DropinFooter>
                    <Button
                        id="select-payment-source-method-bank-button"
                        color="primary"
                        disabled={this.props.requesting}
                        block
                        onClick={() => this.props.setAddPaymentSourceMethodAction(PAYMENT_SOURCE_METHOD.BANK)}
                    >
                        {this.props.requesting ? <Loader color="light" size="sm" /> : 'Link a Bank Account'}
                    </Button>
                    <Button
                        id="select-payment-source-method-cc-button"
                        color="primary"
                        disabled={this.props.requesting}
                        block
                        onClick={() => this.props.setAddPaymentSourceMethodAction(PAYMENT_SOURCE_METHOD.CREDIT_CARD)}
                    >
                        {this.props.requesting ? <Loader color="light" size="sm" /> : 'Link a Card'}
                    </Button>
                </DropinFooter>
            </DropinContent>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state: ApplicationState) => ({
    merchantDisplayName: state.dropin.merchant?.display_name || '',
    requesting: state.paymentSource.requesting,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            setAddPaymentSourceMethodAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(SelectPaymentSourceMethodStep);
