import {AppThunkAction} from '../configureStore';
import {client, Response, RetryAddPaymentSourceModel} from '../../api';
import * as Sentry from '../../sentry';
import * as DataDog from '../../datadog';
import {ApplicationAction} from '../actions';
import {ADD_PAYMENT_SOURCE_STEP, CardSourceConfig, PAYMENT_SOURCE_METHOD} from '../types';
import notifier from '../../dropin/notifier';
import {MESSAGE} from '../../message';
import {ERROR_CODE, StrongholdPayError} from '@stronghold/pay-dropin';
import {StatusType} from "@datadog/browser-logs";
import {AGGREGATOR_ERROR_CODES} from "../../errorCodes";
import {PlaidLinkOnSuccessMetadata} from "react-plaid-link";

export function getAddPaymentSourceConfigAction(payLinkId: string): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        dispatch({ type: 'SET_PAYMENT_SOURCE_REQUESTING', payload: true });
        try {
            const body = await client.getAddPaymentSourceConfig(payLinkId);
            dispatch({ type: 'SET_PAYMENT_SOURCE_REQUESTING', payload: false });
            dispatch({
                type: 'SET_ADD_PAYMENT_SOURCE_CONFIG',
                payload: body.result,
            });

            if (body.result.plaid?.link_token) {
                await client.createPlaidLinkTokenCacheValue(body.result.plaid.cache_key, {
                    customer_token: client.customerToken,
                    publishable_key: client.publishableKey,
                    link_token: body.result.plaid.link_token,
                });
            }
            
            dispatch({
                type: 'SET_ADD_PAYMENT_SOURCE_STEP',
                payload: ADD_PAYMENT_SOURCE_STEP.LINK,
            });
        } catch (error) {
            if (error instanceof StrongholdPayError) {
                dispatch({ type: 'SET_ERROR', payload: error });
            }
            dispatch({ type: 'SHOW_ERROR', payload: true });
        }
    };
}

export function setAddPaymentSourceMethodAction(method: PAYMENT_SOURCE_METHOD): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        switch (method) {
            case PAYMENT_SOURCE_METHOD.BANK:
                dispatch({
                    type: 'SET_ADD_PAYMENT_SOURCE_STEP',
                    payload: ADD_PAYMENT_SOURCE_STEP.ONE,
                });
                break;
            case PAYMENT_SOURCE_METHOD.CREDIT_CARD:
                dispatch({
                    type: 'SET_ADD_PAYMENT_SOURCE_STEP',
                    payload: ADD_PAYMENT_SOURCE_STEP.CC_ONE,
                });
                break;
        }
    };
}

export function plaidAddPaymentSourceSuccessAction(
    public_token: string,
    metadata: PlaidLinkOnSuccessMetadata,
    account_id: string | null,
): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        Sentry.addSentryBreadcrumb(`[Plaid] Connect flow succeeded with token: ${public_token}`);
        DataDog.addDataDogLog(`[Plaid] Connect flow succeeded.`,
            {public_token: public_token}, StatusType.info);
        
        const account = metadata.accounts[0];
        const accountId = account?.id ?? account_id;

        const payLinkId = getState().dropin.query?.payLinkId || undefined;
        dispatch({ type: 'SET_PAYMENT_SOURCE_REQUESTING', payload: true });

        try {
            const body = await client.linkPlaidPaymentSource(public_token, accountId, payLinkId);
            notifier.success(body.result);
            dispatch({ type: 'SET_PAYMENT_SOURCE_REQUESTING', payload: false });
            dispatch({ type: 'SET_PAYMENT_SOURCE', payload: body.result });
            dispatch({
                type: 'SET_ADD_PAYMENT_SOURCE_STEP',
                payload: ADD_PAYMENT_SOURCE_STEP.SUCCESS,
            });
        } catch (error) {
            const config = {
                public_token: public_token,
                plaid_account_id: accountId,
            } as RetryAddPaymentSourceModel;
            handleBankLinkErrors(error, "Plaid", config, dispatch);
        }
    };
}

export function yodleeAddPaymentSourceSuccessAction(
    providerAccountId: number,
    sites: YodleeSite[],
): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        Sentry.addSentryBreadcrumb(`[Yodlee] Connect flow succeeded with providerAccountId: ${providerAccountId}`);
        DataDog.addDataDogLog(`[Yodlee] Connect flow succeeded.`, 
            {providerAccountId: providerAccountId}, StatusType.info);
        
        const payLinkId = getState().dropin.query?.payLinkId;
        dispatch({ type: 'SET_PAYMENT_SOURCE_REQUESTING', payload: true });

        try {
            const body = await client.linkYodleePaymentSource({
                providerAccountId,
                sites,
                payLinkId,
            });
            notifier.success(body.result);
            dispatch({ type: 'SET_PAYMENT_SOURCE_REQUESTING', payload: false });
            dispatch({ type: 'SET_PAYMENT_SOURCE', payload: body.result });
            dispatch({
                type: 'SET_ADD_PAYMENT_SOURCE_STEP',
                payload: ADD_PAYMENT_SOURCE_STEP.SUCCESS,
            });
        } catch (error) {
            const config = {
                provider_account_id: providerAccountId,
            } as RetryAddPaymentSourceModel;
            handleBankLinkErrors(error, "Yodlee", config, dispatch);
        }
    };
}

function handleBankLinkErrors(error: any, aggregatorName: string, config: RetryAddPaymentSourceModel, dispatch: any) {
    if (error instanceof StrongholdPayError) {
        if (error.code === ERROR_CODE.INVALID_FIELD) {
            dispatch({
                type: 'SET_ERROR_MESSAGE',
                payload: MESSAGE.NO_SUPPORTED_PAYMENT_METHOD,
            });
            // TODO: Add new BANK_LINKING_* error codes to JS SDK ERROR_CODE enum
            // Temporarily using string value to avoid JS SDK version upgrade
        } else if (AGGREGATOR_ERROR_CODES.AGGREGATOR_SUPPORT_ERROR === error.code.toString()) {
            dispatch({
                type: 'SET_ERROR_MESSAGE',
                payload: MESSAGE.BANK_LINKING_AGGREGATOR_SUPPORT.replace("{aggregator}", aggregatorName),
            });
        } else if (AGGREGATOR_ERROR_CODES.INCOMPATIBLE_YODLEE_ACCOUNT === error.code.toString()) {
            dispatch({
                type: 'SET_ERROR_MESSAGE',
                payload: MESSAGE.BANK_LINKING_STRONGHOLD_SUPPORT,
            })
        } else if (AGGREGATOR_ERROR_CODES.AGGREGATOR_INTEGRATION_ERROR === error.code.toString()) {
            dispatch({
                type: 'SET_ERROR_MESSAGE',
                payload: MESSAGE.BANK_LINKING_AGGREGATOR_INTEGRATION_ERROR,
            })
        } else if (AGGREGATOR_ERROR_CODES.AGGREGATOR_RETRYABLE_ACTION === error.code.toString()) {
            dispatch({
                type: 'SET_RETRY_PAYMENT_SOURCE_LINK_MODEL',
                payload: config,
            });
            dispatch({
                type: 'SET_ADD_PAYMENT_SOURCE_STEP',
                payload: ADD_PAYMENT_SOURCE_STEP.LOGIN_REQUIRED_VERIFY,
            });
            // Returning here to skip the SET_ERROR and SHOW_ERROR dispatches  
            return;
        }

        dispatch({ type: 'SET_ERROR', payload: error });
    }
    dispatch({ type: 'SHOW_ERROR', payload: true });
}

export function finicityListAccountsAction(): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        Sentry.addSentryBreadcrumb(`[Finicity] Connect flow succeeded`);
        DataDog.addDataDogLog(`[Finicity] Connect flow succeeded`,
            {aggregator: 'Finicity'}, StatusType.info);

        dispatch({ type: 'SET_PAYMENT_SOURCE_REQUESTING', payload: true });

        let body: Response<FinicityAccount[]>;
        try {
            body = await client.listFinicityAccounts();
            dispatch({ type: 'SET_FINICITY_ACCOUNTS_ACTION', payload: body.result });
        } catch (error) {
            if (error instanceof StrongholdPayError) {
                dispatch({ type: 'SET_ERROR', payload: error });
            }
            dispatch({ type: 'SHOW_ERROR', payload: true });
            return;
        }

        if (body.result.length === 1) {
            finicityAddPaymentSourceSuccessAction(body.result[0].id)(dispatch, getState);
        } else {
            dispatch({ type: 'SET_PAYMENT_SOURCE_REQUESTING', payload: false });
            dispatch({
                type: 'SET_ADD_PAYMENT_SOURCE_STEP',
                payload: ADD_PAYMENT_SOURCE_STEP.SELECTION,
            });
        }
    };
}

export function finicityAddPaymentSourceSuccessAction(finicityAccountId: number): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        const payLinkId = getState().dropin.query?.payLinkId || undefined;
        dispatch({ type: 'SET_PAYMENT_SOURCE_REQUESTING', payload: true });

        try {
            const body = await client.linkFinicityPaymentSource({
                finicityAccountId,
                payLinkId,
            });
            notifier.success(body.result);
            dispatch({ type: 'SET_PAYMENT_SOURCE_REQUESTING', payload: false });
            dispatch({ type: 'SET_PAYMENT_SOURCE', payload: body.result });
            dispatch({
                type: 'SET_ADD_PAYMENT_SOURCE_STEP',
                payload: ADD_PAYMENT_SOURCE_STEP.SUCCESS,
            });
        } catch (error) {
            if (error instanceof StrongholdPayError) {
                dispatch({ type: 'SET_ERROR', payload: error });
            }
            dispatch({ type: 'SHOW_ERROR', payload: true });
            return;
        }
    };
}

export function creditCardAddPaymentSourceSuccessAction(data: CardSourceConfig): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        dispatch({ type: 'SET_PAYMENT_SOURCE_REQUESTING', payload: true });

        try {
            const body = await client.addPaymentSourceCreditCard(data);
            notifier.success(body.result);
            dispatch({ type: 'SET_PAYMENT_SOURCE_REQUESTING', payload: false });
            dispatch({ type: 'SET_PAYMENT_SOURCE', payload: body.result });
            dispatch({
                type: 'SET_ADD_PAYMENT_SOURCE_STEP',
                payload: ADD_PAYMENT_SOURCE_STEP.SUCCESS,
            });
        } catch (error) {
            if (error instanceof StrongholdPayError) {
                dispatch({ type: 'SET_ERROR', payload: error });
            }
            dispatch({ type: 'SHOW_ERROR', payload: true });
            return;
        }
    };
}

export function setCardSourceConfigSuccessAction(data: CardSourceConfig): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        dispatch({ type: 'SET_PAYMENT_SOURCE_REQUESTING', payload: true });
        dispatch({ type: 'SET_CARD_SOURCE_CONFIGURATION', payload: data });
        dispatch({ type: 'SET_PAYMENT_SOURCE_REQUESTING', payload: false });
    };
}
