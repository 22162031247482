import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { CUSTOMER_LOGIN_STEP, CUSTOMER_PAGE, CUSTOMER_SIGNUP_STEP, CustomerState } from '../types';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: CustomerState = {
    page: CUSTOMER_PAGE.SIGNUP,
    signupStep: CUSTOMER_SIGNUP_STEP.ONE,
    loginStep: CUSTOMER_LOGIN_STEP.ONE,
    requesting: false,
    authenticationMethod: 'sms',
    token: null,
    signup: null,
    login: null,
};

export const customer: Reducer<CustomerState, ApplicationAction> = (
    state: CustomerState = unloadedState,
    action: ApplicationAction,
) => {
    switch (action.type) {
        case 'SET_CUSTOMER_PAGE':
            return {
                ...state,
                page: action.payload,
            };
        case 'SET_CUSTOMER_SIGNUP_STEP':
            return {
                ...state,
                signupStep: action.payload,
            };
        case 'SET_CUSTOMER_LOGIN_STEP':
            return {
                ...state,
                loginStep: action.payload,
            };
        case 'SET_CUSTOMER_REQUESTING':
            return {
                ...state,
                requesting: action.payload,
            };
        case 'SET_AUTHENTICATION_METHOD':
            return {
                ...state,
                authenticationMethod: action.payload,
            };
        case 'SET_AUTHENTICATION_TOKEN':
            return {
                ...state,
                token: action.payload,
            };
        case 'SET_CUSTOMER_SIGNUP':
            return {
                ...state,
                signup: action.payload,
            };
        case 'SET_CUSTOMER_LOGIN':
            return {
                ...state,
                login: action.payload,
            };
        default:
            return state;
    }
};
