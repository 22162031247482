import React, { FormEvent, Ref } from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState, AuthenticationMethod, CUSTOMER_LOGIN_STEP, CustomerLoginRequestModel } from '../store';
import { bindActionCreators, Dispatch } from 'redux';
import { Form, FormFeedback } from 'reactstrap';
import { loginAction, setAuthenticationMethod, setCustomerLoginStep } from '../store/actionCreators/findCustomer';
import FormGroup from 'reactstrap/lib/FormGroup';
import PhoneNumberInput from './PhoneNumberInput';
import { StrongholdPayError } from '@stronghold/pay-dropin';
import { isPropertyError } from '../store/selectors';
import { getErrorMessage, isValidEmail } from '../dropin/utils';
import Input from 'reactstrap/lib/Input';

interface StateProps {
    requesting: boolean;
    authenticationMethod: AuthenticationMethod;
    error: StrongholdPayError | null;
    login: CustomerLoginRequestModel | null;
}

interface State {
    mobile: string;
    email: string;
}

interface OwnProps {
    submitRef: Ref<any>;
    disabled: (disabled: boolean) => void;
}

interface DispatchProps {
    setCustomerLoginStep: typeof setCustomerLoginStep;
    setAuthenticationMethod: typeof setAuthenticationMethod;
    customerLogin: typeof loginAction;
    resetError: () => void;
}

type Props = StateProps & OwnProps & DispatchProps;

class LoginForm extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            mobile:
                this.props.login && this.props.login.authentication_method === 'sms' ? this.props.login.username : '',
            email:
                this.props.login && this.props.login.authentication_method === 'email' ? this.props.login.username : '',
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onLogin = async (authenticationMethod: AuthenticationMethod) => {
        let username = authenticationMethod === 'sms' ? this.state.mobile : this.state.email;
        let request = {
            authentication_method: authenticationMethod,
            username: username,
        } as CustomerLoginRequestModel;
        await this.props.customerLogin(request);

        if (!this.props.error) {
            this.props.setCustomerLoginStep(CUSTOMER_LOGIN_STEP.TWO);
        }
    };

    onSubmit = () => async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await this.onLogin(this.props.authenticationMethod);
    };

    toggleAuthMethod = (authMethod: AuthenticationMethod) => {
        this.props.setAuthenticationMethod(authMethod);
    };

    render() {
        const { requesting, error, authenticationMethod, submitRef, resetError, disabled } = this.props;
        const { mobile, email } = this.state;

        const isDisabled =
            error !== null ||
            requesting ||
            (authenticationMethod === 'email' && !isValidEmail(email)) ||
            (authenticationMethod === 'sms' && !mobile);
        disabled(isDisabled);

        return (
            <React.Fragment>
                <div className="mb-1">To log in, please enter your mobile phone number or email address.</div>
                <Form id="login-form" onSubmit={this.onSubmit()} noValidate>
                    <FormGroup inline>
                        <div className="radio">
                            <label className={'contact-method'}>
                                <input
                                    type="radio"
                                    value="SMS"
                                    checked={authenticationMethod === 'sms'}
                                    onChange={() => {
                                        resetError();
                                        this.toggleAuthMethod('sms');
                                    }}
                                />
                                SMS
                            </label>
                            <label className={'contact-method'}>
                                <input
                                    type="radio"
                                    value="Email"
                                    checked={authenticationMethod === 'email'}
                                    onChange={() => {
                                        resetError();
                                        this.toggleAuthMethod('email');
                                    }}
                                />
                                Email
                            </label>
                        </div>
                        {this.props.authenticationMethod === 'sms' ? (
                            <FormGroup>
                                <PhoneNumberInput
                                    placeholder="Enter mobile phone number"
                                    value={mobile}
                                    isInvalid={error !== null && isPropertyError('username', error)}
                                    onChange={(mobile: string) => {
                                        resetError();
                                        this.setState({ mobile });
                                    }}
                                />
                                <FormFeedback>
                                    {error &&
                                        isPropertyError('username', error) &&
                                        getErrorMessage(error, error.message)}
                                </FormFeedback>
                            </FormGroup>
                        ) : (
                            <FormGroup>
                                <Input
                                    id={'input-email'}
                                    key={'input-email'}
                                    type="email"
                                    placeholder="Enter email address"
                                    autoComplete="email"
                                    value={email}
                                    onChange={(e) => {
                                        resetError();
                                        this.setState({
                                            email: e.currentTarget.value,
                                        });
                                    }}
                                    invalid={isPropertyError('username', error)}
                                />
                                <FormFeedback>
                                    {error &&
                                        isPropertyError('username', error) &&
                                        getErrorMessage(error, error.message)}
                                </FormFeedback>
                            </FormGroup>
                        )}
                    </FormGroup>
                    <button ref={submitRef} type={'submit'} disabled={isDisabled} style={{ display: 'none' }} />
                </Form>
            </React.Fragment>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state: ApplicationState) => ({
    requesting: state.customer.requesting,
    authenticationMethod: state.customer.authenticationMethod,
    error: state.error?.error,
    login: state.customer.login,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            setCustomerLoginStep,
            setAuthenticationMethod,
            customerLogin: loginAction,
            resetError: () => dispatch({ type: 'RESET_ERROR' }),
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
