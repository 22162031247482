import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState, CUSTOMER_PAGE } from '../../store';
import notifier from '../../dropin/notifier';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationAction } from '../../store/actions';
import Login from '../../components/Login';
import Signup from '../../components/Signup';
import { setCustomerPage } from '../../store/actionCreators/findCustomer';

interface StateProps {
    page: CUSTOMER_PAGE;
}

interface DispatchProps {
    setCustomerPage: typeof setCustomerPage;
}

type Props = StateProps & DispatchProps;

class FindCustomerPage extends React.PureComponent<Props> {
    componentDidMount() {
        notifier.ready();
    }

    render() {
        if (this.props.page === CUSTOMER_PAGE.LOGIN) {
            return <Login />;
        } else {
            return <Signup />;
        }
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state: ApplicationState) => ({
    page: state.customer.page,
});

const mapDispatchToProps = (dispatch: Dispatch<ApplicationAction>) =>
    bindActionCreators(
        {
            setCustomerPage,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(FindCustomerPage);
