import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import AddChargeStepOne from '../../components/AddChargeStepOne';
import PaymentSucceeded from '../../components/PaymentSucceeded';
import { ADD_CHARGE_STEP, ApplicationState, getAddChargeConfigAction, createChargeAction } from '../../store';
import { ApplicationAction } from '../../store/actions';
import { bindActionCreators, Dispatch } from 'redux';
import notifier from '../../dropin/notifier';
import UpdatePaymentSourceStepOne from '../../components/UpdatePaymentSourceStepOne';
import AddOrUpdatePaymentSourceStep from '../../components/AddOrUpdatePaymentSourceStep';
import { setCreditValue } from '../../store/actionCreators/credits';
import { setProcessingFee } from "../../store/actionCreators/processingFees";

interface StateProps {
    requesting: boolean;
    step: ADD_CHARGE_STEP | null;
    skipPayScreen: boolean;
    skipSuccessScreen: boolean;
}

interface DispatchProps {
    getAddChargeConfigAction: typeof getAddChargeConfigAction;
    createChargeAction: typeof createChargeAction;
    setStep: (step: ADD_CHARGE_STEP) => void;
    setCreditValue: typeof setCreditValue;
    setProcessingFee: typeof setProcessingFee;
}

type Props = StateProps & DispatchProps;

class AddChargePage extends React.PureComponent<Props> {
    async componentDidMount() {
        this.props.setProcessingFee();
        this.props.setCreditValue();
        this.props.getAddChargeConfigAction();
    }

    componentDidUpdate(prev: Props) {
        if (prev.step === this.props.step) {
            return;
        }

        if (this.props.step === ADD_CHARGE_STEP.ONE) {
            if (this.props.skipPayScreen) {
                this.props.createChargeAction();
            }
        }

        if (this.props.step === ADD_CHARGE_STEP.SUCCESS) {
            if (this.props.skipSuccessScreen) {
                notifier.exit();
            }
        }
    }

    render() {
        if (this.props.step === ADD_CHARGE_STEP.ONE) {
            if (this.props.skipPayScreen) {
                return null;
            }
            return (
                <AddChargeStepOne requesting={this.props.requesting} onClick={() => this.props.createChargeAction()} />
            );
        } else if (this.props.step === ADD_CHARGE_STEP.LOGIN_REQUIRED) {
            return (
                <UpdatePaymentSourceStepOne
                    showLoading={!this.props.skipPayScreen}
                    onClick={() => this.props.setStep(ADD_CHARGE_STEP.UPDATE)}
                />
            );
        } else if (this.props.step === ADD_CHARGE_STEP.UPDATE) {
            return (
                <AddOrUpdatePaymentSourceStep mode="update" onUpdate={() => this.props.setStep(ADD_CHARGE_STEP.ONE)} />
            );
        } else if (this.props.step === ADD_CHARGE_STEP.SUCCESS) {
            if (this.props.skipPayScreen) {
                return null;
            }
            return <PaymentSucceeded onClick={() => notifier.exit()} type="charge" />;
        } else {
            return null;
        }
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state: ApplicationState) => ({
    step: state.transaction.addChargeStep,
    requesting: state.transaction.requesting,
    skipPayScreen: state.dropin.query.skipPayScreen,
    skipSuccessScreen: state.dropin.query.skipSuccessScreen,
});

const mapDispatchToProps = (dispatch: Dispatch<ApplicationAction>) =>
    bindActionCreators(
        {
            getAddChargeConfigAction,
            createChargeAction,
            setStep: (step: ADD_CHARGE_STEP) =>
                dispatch({
                    type: 'SET_ADD_CHARGE_STEP',
                    payload: step,
                }),
            setCreditValue: setCreditValue,
            setProcessingFee,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddChargePage);
