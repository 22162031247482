import * as React from 'react';
import PhoneInput from 'react-phone-input-2';

interface Props {
    value: string;
    placeholder?: string;
    isInvalid: boolean;
    onChange: (phone: string) => void;
    disabled?: boolean;
}

class PhoneNumberInput extends React.Component<Props> {
    render() {
        const { value, placeholder, isInvalid, onChange, disabled} = this.props;

        return (
            <PhoneInput
                placeholder={placeholder && 'Enter mobile phone number'}
                value={value}
                country={'us'}
                onChange={(mobile) => {
                    mobile = mobile.replace(/[^0-9+]/g, '');
                    onChange(`+${mobile}`);
                }}
                containerClass={['form-control', isInvalid ? 'is-invalid' : ''].join(' ')}
                disabled={disabled}
                inputProps={{
                    name: 'mobile_phone_number',
                    className: [
                        disabled ? 'disabled' : '',
                        'border-top-0',
                        'border-right-0',
                        'border-left-0',
                        'border-bottom-0',
                    ].join(' '),
                    disabled: disabled,
                }}
            />
        );
    }
}

export default PhoneNumberInput;
