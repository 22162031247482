import {PaymentSource} from '@stronghold/pay-dropin';
import {AddPaymentSourceConfig, RetryAddPaymentSourceModel, UpdatePaymentSourceConfig} from '../../api';

export enum PAYMENT_SOURCE_METHOD {
    CREDIT_CARD = 'credit_card',
    BANK = 'bank',
}

export enum ADD_PAYMENT_SOURCE_STEP {
    ONE = 'one',
    CC_ONE = 'cc_one',
    METHOD = 'method',
    LINK = 'link',
    LOGIN_REQUIRED = 'login_required',
    LOGIN_REQUIRED_VERIFY = 'login_required_verify',
    SELECTION = 'selection',
    SUCCESS = 'success',
}

export enum UPDATE_PAYMENT_SOURCE_STEP {
    ONE = 'one',
    UPDATE = 'update',
    SUCCESS = 'success',
}

export interface PaymentSourceState {
    addStep: ADD_PAYMENT_SOURCE_STEP;
    config: AddPaymentSourceConfig | UpdatePaymentSourceConfig | null;
    creditCardConfig: CardSourceConfig | null;
    updateStep: UPDATE_PAYMENT_SOURCE_STEP;
    requesting: boolean;
    paymentSource: PaymentSource | null;
    retryModel: RetryAddPaymentSourceModel | null;
}

export interface CardSourceConfig {
    cardHolderName: string | null;
    tsepToken: string | null;
    expirationDate: string | null;
    cvv2: string | null;
    cardType: string | null;
    maskedCardNumber: string | null;
    zip: string | null;
}
