import { AppThunkAction } from '../configureStore';
import { ApplicationAction } from '../actions';
import { client } from '../../api';
import {
    AuthenticationMethod,
    CUSTOMER_LOGIN_STEP,
    CUSTOMER_PAGE,
    CUSTOMER_SIGNUP_STEP,
    CustomerLoginRequestModel,
    CustomerSignupRequestModel,
} from '../types';
import notifier from '../../dropin/notifier';

export function setCustomerPage(step: CUSTOMER_PAGE): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        dispatch({ type: 'SET_CUSTOMER_PAGE', payload: step });
    };
}

export function setCustomerLoginStep(step: CUSTOMER_LOGIN_STEP): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        dispatch({ type: 'SET_CUSTOMER_LOGIN_STEP', payload: step });
    };
}

export function setCustomerSignupStep(step: CUSTOMER_SIGNUP_STEP): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        dispatch({ type: 'SET_CUSTOMER_SIGNUP_STEP', payload: step });
    };
}

export function setAuthenticationMethod(method: AuthenticationMethod): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        dispatch({ type: 'SET_AUTHENTICATION_METHOD', payload: method });
    };
}

export function setAuthenticationToken(token: string | null): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        dispatch({ type: 'SET_AUTHENTICATION_TOKEN', payload: token });
    };
}

export function loginAction(data: CustomerLoginRequestModel): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        dispatch({ type: 'SET_CUSTOMER_REQUESTING', payload: true });
        dispatch({ type: 'RESET_ERROR' });
        dispatch({ type: 'SET_CUSTOMER_LOGIN', payload: data });

        try {
            const response = await client.login(data);
            dispatch({ type: 'SET_AUTHENTICATION_TOKEN', payload: response.result.token });
        } catch (e) {
            notifier.ready();
            dispatch({ type: 'SET_ERROR', payload: e });
            dispatch({ type: 'SET_CUSTOMER_LOGIN', payload: null });
        } finally {
            dispatch({ type: 'SET_CUSTOMER_REQUESTING', payload: false });
        }
    };
}

export function signupAction(data: CustomerSignupRequestModel): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        dispatch({ type: 'SET_CUSTOMER_REQUESTING', payload: true });
        dispatch({ type: 'RESET_ERROR' });
        dispatch({ type: 'SET_CUSTOMER_SIGNUP', payload: data });

        try {
            if (data.authentication_method === 'sms') {
                data.customer_details.email = null;
            } else if (data.authentication_method === 'email') {
                data.customer_details.mobile = null;
            }

            const response = await client.signup(data);
            dispatch({ type: 'SET_AUTHENTICATION_TOKEN', payload: response.result.token });
        } catch (e) {
            notifier.ready();
            dispatch({ type: 'SET_ERROR', payload: e });
            dispatch({ type: 'SET_CUSTOMER_SIGNUP', payload: null });
        } finally {
            dispatch({ type: 'SET_CUSTOMER_REQUESTING', payload: false });
        }
    };
}

export function verifyAction(code: string): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        dispatch({ type: 'SET_CUSTOMER_REQUESTING', payload: true });
        dispatch({ type: 'RESET_ERROR' });
        const token = getState().customer.token;

        if (!token) {
            return;
        }

        try {
            const response = await client.verify({ code, token });
            notifier.success(response.result);
        } catch (e) {
            dispatch({ type: 'SET_ERROR', payload: e });
        } finally {
            dispatch({ type: 'SET_CUSTOMER_REQUESTING', payload: false });
        }
    };
}
