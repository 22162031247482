import React from 'react';

interface Props {
    label?: string;
}

export default class EndUserAgreement extends React.PureComponent<Props> {
    render() {
        return (
            <small>
                By selecting {this.props.label || 'Next'} you agree to the{' '}
                <a href="https://stronghold.co/legal" target="_blank" rel="noopener noreferrer">
                    Stronghold Pay End User Agreement
                </a>
            </small>
        );
    }
}
