import { UPDATE_PAYMENT_SOURCE_STEP } from '../types';
import { client } from '../../api';
import { ApplicationAction } from '../actions';
import { AppThunkAction } from '../configureStore';
import { getPaymentSourceId } from '../selectors';

export function getUpdatePaymentSourceConfigAction(): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        const paymentSourceId = getPaymentSourceId(getState()) || '';

        dispatch({ type: 'SET_PAYMENT_SOURCE_REQUESTING', payload: true });
        try {
            const body = await client.getUpdatePaymentSourceConfig(paymentSourceId);
            dispatch({ type: 'SET_PAYMENT_SOURCE_REQUESTING', payload: false });
            dispatch({
                type: 'SET_UPDATE_PAYMENT_SOURCE_CONFIG',
                payload: body.result,
            });
            dispatch({
                type: 'SET_PAYMENT_SOURCE',
                payload: body.result.payment_source,
            });
            if (!body.result.is_re_authentication_needed) {
                dispatch({
                    type: 'SET_UPDATE_PAYMENT_SOURCE_STEP',
                    payload: UPDATE_PAYMENT_SOURCE_STEP.SUCCESS,
                });
            }
        } catch (error) {
            dispatch({ type: 'SET_ERROR', payload: error });
            dispatch({ type: 'SHOW_ERROR', payload: true });
        }
    };
}
