import { ERROR_CODE, ERROR_TYPE, StrongholdPayError } from '@stronghold/pay-dropin';
import { MESSAGE } from '../message';
import moment from 'moment/moment';

export function getDefaultError(
    type?: ERROR_TYPE,
    code?: ERROR_CODE,
    message?: string,
    property?: string,
    reference?: string,
) {
    type = type || ERROR_TYPE.API_ERROR;
    code = code || ERROR_CODE.SERVER_ERROR;
    message = message || 'A server error occurred.';
    property = property || undefined;
    const e = new StrongholdPayError(type, code, message, property, reference);
    return e;
}

export function getErrorMessage(error: StrongholdPayError | null, message: string | null) {
    if (!error) return '';

    if (!message) {
        message = MESSAGE.SOMETHING_WENT_WRONG;
    }

    let text: string;
    switch (error.code) {
        case ERROR_CODE.PAYMENT_SOURCE_ALREADY_EXISTS:
            text = MESSAGE.PAYMENT_METHOD_ALREADY_ADDED;
            break;
        case ERROR_CODE.INSUFFICIENT_BALANCE:
            text = MESSAGE.INSUFFICIENT_BALANCE;
            break;
        case ERROR_CODE.PAYMENT_SOURCE_UNAVAILABLE:
            text = MESSAGE.PAYMENT_SOURCE_UNAVAILABLE;
            break;
        case ERROR_CODE.PAYMENT_SOURCE_LOGIN_UNAVAILABLE:
            text = MESSAGE.PAYMENT_SOURCE_LOGIN_UNAVAILABLE;
            break;
        case ERROR_CODE.PAYMENT_SOURCE_INACTIVE:
            text = MESSAGE.PAYMENT_SOURCE_INACTIVE;
            break;
        case ERROR_CODE.PAYMENT_SOURCE_ACTION_REQUIRED:
            text = MESSAGE.PAYMENT_SOURCE_ACTION_REQUIRED;
            break;
        case ERROR_CODE.MERCHANT_SOFTWARE_ERROR:
            text = MESSAGE.MERCHANT_SOFTWARE_ERROR;
            break;
        case ERROR_CODE.CUSTOMER_BLOCKED:
            text = MESSAGE.ENABLE_TO_USE_STRONGHOLD_PAY;
            break;
        case ERROR_CODE.PAYMENT_SOURCE_BLOCKED:
            text = MESSAGE.FINANCIAL_INSTITUTION_CURRENTLY_NOT_SUPPORTED;
            break;
        case ERROR_CODE.CHARGE_BLOCKED_OUTSTANDING_PAYMENT:
            text = MESSAGE.OUTSTANDING_PAYMENT_NOT_MADE;
            break;
        case ERROR_CODE.CHARGE_BLOCKED_EXCEEDS_LIMIT:
            text = MESSAGE.PURCHASE_LIMIT_EXCEEDED;
            break;
        case ERROR_CODE.CHARGE_TIP_ALREADY_CREATED:
            text = MESSAGE.CHARGE_TIP_ALREADY_EXISTS;
            break;
        case ERROR_CODE.PAYMENT_SOURCE_LOGIN_REQUIRED:
            text = MESSAGE.PAYMENT_SOURCE_LOGIN_REQUIRED;
            break;
        case ERROR_CODE.PAY_LINK_CANCELED:
            text = MESSAGE.PAY_LINK_WAS_CANCELED;
            break;
        case ERROR_CODE.INVALID_CHARGE_AMOUNT:
        case ERROR_CODE.INVALID_TIP_AMOUNT:
            text = message;
            break;
        case ERROR_CODE.INVALID_VERIFICATION_CODE:
            text = MESSAGE.INVALID_VERIFICATION_CODE;
            break;
        case ERROR_CODE.INVALID_CUSTOMER_TOKEN:
            text = MESSAGE.INVALID_CUSTOMER_TOKEN;
            break;
        case ERROR_CODE.EXPIRED_CUSTOMER_TOKEN:
            text = MESSAGE.VERIFICATION_CODE_EXPIRED;
            break;
        case ERROR_CODE.VALUE_TAKEN:
            text = error.message
            break;
        case ERROR_CODE.INVALID_FIELD:
            text = error.message
            break;
        case ERROR_CODE.NOT_FOUND:
            text = message;
            break;
        case ERROR_CODE.CONFLICT:
            text = error.message
            break;
        case ERROR_CODE.BANK_ACCOUNT_VALIDATION_FAILURE:
            text = MESSAGE.BANK_ACCOUNT_VALIDATION_FAILURE;
            break;
        default:
            text = MESSAGE.SOMETHING_WENT_WRONG;
    }

    return text;
}

export function formatDobDisplay(dob: string | null | undefined) {
    const format = 'MM/DD/YYYY';
    return dob ? moment(dob).format(format) : '';
}

export function formatDob(dob: string | null | undefined) {
    const format = 'YYYY-MM-DD';
    return dob ? moment(dob).format(format) : '';
}

export function isValidDob(dob: string | null | undefined) {
    return dob ? moment(dob, 'MM/DD/YYYY', true).isValid() || moment(dob, 'M/D/YYYY', true).isValid() : false;
}

export function isValidEmail(email: string | null | undefined) {
    const format = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email ? format.test(email) : false;
}
