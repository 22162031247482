import { ERROR_CODE, StrongholdPayError } from '@stronghold/pay-dropin';
import { client } from '../../api';
import notifier from '../../dropin/notifier';
import { ApplicationAction } from '../actions';
import { AppThunkAction } from '../configureStore';
import { getPaymentSourceId } from '../selectors';
import { ADD_TIP_STEP } from '../types';

export function getAddTipConfigAction(): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        dispatch({ type: 'SET_TRANSACTION_REQUESTING', payload: true });

        const paymentSourceId = getPaymentSourceId(getState()) || '';
        const chargeId = getState().dropin.query.tip?.chargeId || '';
        try {
            const body = await client.getAddTipConfig(paymentSourceId, chargeId);
            notifier.ready();
            dispatch({ type: 'SET_TRANSACTION_REQUESTING', payload: false });
            dispatch({ type: 'SET_CHARGE', payload: body.result.charge });
            dispatch({
                type: 'SET_PAYMENT_SOURCE',
                payload: body.result.payment_source,
            });
            dispatch({ type: 'SET_ADD_TIP_STEP', payload: ADD_TIP_STEP.ONE });
        } catch (error) {
            notifier.ready();
            dispatch({ type: 'SET_ERROR', payload: error });
            dispatch({ type: 'SHOW_ERROR', payload: true });
        }
    };
}

export function createTipAction(): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        dispatch({ type: 'SET_TRANSACTION_REQUESTING', payload: true });

        const query = getState().dropin.query;
        try {
            const body = await client.postTip({
                authorize_only: query.authorizeOnly,
                pay_link_id: query.payLinkId || undefined,
                tip: {
                    amount: query.tip?.amount || 0,
                    beneficiary_name: query.tip?.beneficiaryName || '',
                    charge_id: query.tip?.chargeId || '',
                    payment_source_id: query.tip?.paymentSourceId || '',
                    details: {
                        display_message: query.tip?.details?.displayMessage || null,
                        drawer_id: query.tip?.details?.drawerId || null,
                    },
                },
            });
            notifier.success(body.result);
            dispatch({ type: 'SET_TRANSACTION_REQUESTING', payload: false });
            dispatch({ type: 'SET_TIP', payload: body.result });
            dispatch({
                type: 'SET_ADD_TIP_STEP',
                payload: ADD_TIP_STEP.SUCCESS,
            });
        } catch (error) {
            notifier.ready();
            dispatch({ type: 'SET_TRANSACTION_REQUESTING', payload: false });
            if (error instanceof StrongholdPayError) {
                if (error.code === ERROR_CODE.PAYMENT_SOURCE_LOGIN_REQUIRED) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: error,
                        notify: false,
                    });
                    dispatch({
                        type: 'SET_ADD_TIP_STEP',
                        payload: ADD_TIP_STEP.LOGIN_REQUIRED,
                    });
                    return;
                }
            }
            dispatch({ type: 'SET_ERROR', payload: error });
            dispatch({ type: 'SHOW_ERROR', payload: true });
        }
    };
}
