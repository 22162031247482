import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { setCustomerSignupStep } from '../store/actionCreators/findCustomer';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState, CUSTOMER_SIGNUP_STEP } from '../store';
import DropinContent from './DropinContent';
import DropinBody from './DropinBody';
import CoBrand from './CoBrand';
import DropinFooter from './DropinFooter';
import Button from 'reactstrap/lib/Button';
import Loader from './Loader';
import CustomerFooter from './CustomerFooter';

interface StateProps {
    merchantDisplayName: string;
    requesting: boolean;
}

interface DispatchProps {
    setCustomerSignupStep: typeof setCustomerSignupStep;
}

type Props = StateProps & DispatchProps;
class CustomerSignupStepOne extends React.PureComponent<Props> {
    render() {
        return (
            <DropinContent>
                <DropinBody>
                    <CoBrand />
                    <div className="mb-3">
                        Sign up for payments at <strong>{this.props.merchantDisplayName}</strong>
                    </div>
                    <div className="mb-1">We need a few details in order to create your account.</div>
                </DropinBody>
                <DropinFooter>
                    <Button
                        id="new-customer-next-step-button"
                        color="primary"
                        disabled={this.props.requesting}
                        block
                        onClick={() => this.props.setCustomerSignupStep(CUSTOMER_SIGNUP_STEP.TWO)}
                    >
                        {this.props.requesting ? <Loader color="light" size="sm" /> : 'Next'}
                    </Button>
                    <CustomerFooter />
                </DropinFooter>
            </DropinContent>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            setCustomerSignupStep,
        },
        dispatch,
    );

const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state: ApplicationState) => ({
    merchantDisplayName: state.dropin.merchant?.display_name || '',
    requesting: state.customer.requesting,
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSignupStepOne);
