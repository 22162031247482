import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import * as querystring from 'query-string';
import { ApplicationState } from '../store';
import { bindActionCreators, Dispatch } from 'redux';
import { isMobile } from 'react-device-detect';
import { initDropinAction } from '../store';
import DropinError from './DropinError';

interface StateProps {
    ready: boolean;
    showError: boolean;
}

interface OwnProps {
    children?: React.ReactNode;
}

interface DispatchProps {
    initDropinAction: typeof initDropinAction;
}

type Props = StateProps & OwnProps & DispatchProps & RouteComponentProps;

interface State {
    isMobile: boolean;
}

class DropinLayout extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isMobile,
        };
    }

    componentDidMount() {
        const search = this.props.location.search;
        const parsed = querystring.parse(search);
        const pathname = this.props.location.pathname
        this.props.initDropinAction(parsed, pathname);
    }

    render() {
        if (!this.props.ready) {
            return null;
        }

        if (this.props.showError) {
            return <DropinError />;
        }

        return <>{this.props.children}</>;
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state: ApplicationState) => ({
    ready: state.dropin.ready,
    showError: state.error.show,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            initDropinAction,
        },
        dispatch,
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DropinLayout));
