export const MESSAGE = {
    SOMETHING_WENT_WRONG: 'An error has occurred with your request. Please try again or contact your merchant.',
    NO_SUPPORTED_PAYMENT_METHOD_FOR_INSTITUTION:
        "We couldn't find a supported checking account at this institution. Please link a different bank.",
    NO_SUPPORTED_PAYMENT_METHOD:
        'The account selected at this institution is not a supported checking account. Please link a different account.',
    PAYMENT_METHODS_ALREADY_ADDED: 'All the bank accounts have already been added.',
    PAYMENT_METHOD_ALREADY_ADDED: 'This bank account has already been added.',
    MERCHANT_SOFTWARE_ERROR:
        'We are unable to process your payment due to an error with the software the merchant uses.',
    ENABLE_TO_USE_STRONGHOLD_PAY: 'You are unable to use Stronghold Pay at this time.',
    OUTSTANDING_PAYMENT_NOT_MADE: 'You are unable to use Stronghold Pay until an outstanding payment has been made.',
    PURCHASE_LIMIT_EXCEEDED:
        'You have exceeded your Stronghold Pay purchase limit. This limit takes into account your purchases made in the last three days, so please wait some time or place a smaller order.',
    PAYMENT_SOURCE_BLOCKED:
        'Your financial institution is not supported at this moment. Please retry with a different bank account or pay with a different payment method.',
    FINANCIAL_INSTITUTION_CURRENTLY_NOT_SUPPORTED:
        'Your financial institution is not supported at this moment. Please retry with a different bank account or pay with a different payment method.',
    BANK_LINKING_AGGREGATOR_SUPPORT: 'Please try again or contact {aggregator} support for additional assistance.',
    BANK_LINKING_AGGREGATOR_INTEGRATION_ERROR: 'We are unable to link your bank account at this moment.',
    BANK_LINKING_STRONGHOLD_SUPPORT: 'We are unable to link your bank account at this moment.',
    PAY_LINK_WAS_CANCELED: 'This link to pay is no longer active. Please contact the merchant for another way to make your payment.',
    INVALID_VERIFICATION_CODE: 'The provided verification code was invalid. Please try again or resend a new code.',
    VERIFICATION_CODE_EXPIRED: 'The provided verification code has expired. Please resend a new code.',
    INVALID_CUSTOMER_TOKEN: 'The provided customer token was invalid. Please try again or resend a new code.',
    VALUE_TAKEN: 'Value is already taken.',
    INVALID_FIELD: 'Value is not valid.',
    CHARGE_TIP_ALREADY_EXISTS: "You've already tipped for this order. Thanks for your support!",
    PAYMENT_SOURCE_LOGIN_REQUIRED: "We're unable to connect to your financial institution to verify your balance at the moment. Authentication is required.",
    INSUFFICIENT_BALANCE: "The bank account you've selected does not have sufficient funds for this purchase. Please add funds to your account or link another account.",
    PAYMENT_SOURCE_UNAVAILABLE: "We're unable to connect to your financial institution to verify your balance at the moment. Please try again later.",
    PAYMENT_SOURCE_LOGIN_UNAVAILABLE: "We're unable to connect to your account. We currently do not support the multi-factor authentication of your financial institution. Please try again later.",
    PAYMENT_SOURCE_INACTIVE: "Your payment method has been disconnected. Please relink or use a different account or method.",
    PAYMENT_SOURCE_ACTION_REQUIRED: "Your bank provider requires you to log into their internet banking website. Please log in and take any requested actions, then retry this payment.",
    BANK_ACCOUNT_VALIDATION_FAILURE: "This bank account cannot be validated at this time. Please try another account."
};
