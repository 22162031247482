import React from 'react';
import {connect, MapStateToPropsParam} from 'react-redux';
import CoBrand from './CoBrand';
import DropinBody from './DropinBody';
import DropinContent from './DropinContent';
import DropinFooter from './DropinFooter';
import {ApplicationState} from '../store';
import * as selectors from '../store/selectors';
import {bindActionCreators, Dispatch} from 'redux';
import Button from 'reactstrap/lib/Button';

interface StateProps {
    amount: string;
}

interface OwnProps {
    type: 'charge' | 'tip';
    onClick: () => void;
}

interface DispatchProps {}

type Props = StateProps & OwnProps & DispatchProps;

class PaymentSucceeded extends React.PureComponent<Props> {
    render() {
        return (
            <DropinContent>
                <DropinBody>
                    <CoBrand />
                    <div data-sh="message">
                        Thanks, your payment of <strong>{this.props.amount}</strong> has been made successfully.
                    </div>
                </DropinBody>
                <DropinFooter>
                    <Button id="finish-button" color="primary" block onClick={this.props.onClick}>
                        Finish
                    </Button>
                </DropinFooter>
            </DropinContent>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (
    state: ApplicationState,
    ownProps: OwnProps,
) => {
    let amount = 0;
    if (ownProps.type === 'charge') {
        amount = (state.dropin.query.charge?.amount || 0) + (state.dropin.query.tip?.amount || 0);

        const addConvenienceFee = state.transaction.addChargeConfig?.add_convenience_fee || false;

        // If Treez or Jane and not using paylinks, add the convenience fee to the total amount.
        if (addConvenienceFee && state.dropin.query?.payLinkId === null) {
            amount += state.transaction.addChargeConfig?.convenience_fee || 0;
        }

        if(state.transaction.creditValue){
            amount = amount > state.transaction.creditValue ? amount - state.transaction.creditValue : 0;
        }

    } else if (ownProps.type === 'tip') {
        amount = state.dropin.query.tip?.amount || 0;
    }

    return {
        amount: selectors.toCurrency(amount + (state.transaction.processingFee || 0)) || '',
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSucceeded);
