import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState, CUSTOMER_SIGNUP_STEP } from '../store';
import { setCustomerPage } from '../store/actionCreators/findCustomer';
import { bindActionCreators, Dispatch } from 'redux';
import CustomerSignupStepOne from './CustomerSignupStepOne';
import CustomerSignupStepTwo from './CustomerSignupStepTwo';
import CustomerSignupStepThree from './CustomerSignupStepThree';
import CustomerSignupStepSuccess from './CustomerSignupStepSuccess';

interface StateProps {
    requesting: boolean;
    step: CUSTOMER_SIGNUP_STEP;
}

interface DispatchProps {
    setCustomerPage: typeof setCustomerPage;
}

type Props = StateProps & DispatchProps;

class Signup extends React.PureComponent<Props> {
    render() {
        if (this.props.step === CUSTOMER_SIGNUP_STEP.ONE) {
            return <CustomerSignupStepOne />;
        } else if (this.props.step === CUSTOMER_SIGNUP_STEP.TWO) {
            return <CustomerSignupStepTwo />;
        } else if (this.props.step === CUSTOMER_SIGNUP_STEP.THREE) {
            return <CustomerSignupStepThree />;
        } else if (this.props.step === CUSTOMER_SIGNUP_STEP.SUCCESS) {
            return <CustomerSignupStepSuccess />;
        } else {
            return null;
        }
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state: ApplicationState) => ({
    requesting: state.customer.requesting,
    step: state.customer.signupStep,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            setCustomerPage,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
